import { takeLatest, all, call, put, cancelled } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import {
  RequestGenerateOffer,
  RequestPriceCalculation,
  RequestRecord,
  RequestRecordOffers,
  SubmitOffer,
} from '../types/actions';
import { Offer, OfferState } from '../types/models';
import * as api from '../api';
import {
  offerError,
  priceCalculationError,
  receiveOffer,
  receivePriceCalculation,
  receiveRecordOffers,
  recordError,
} from '../actions';
import {
  REQUEST_GENERATE_OFFER,
  REQUEST_OFFER,
  REQUEST_PRICE_CALCULATION,
  REQUEST_RECORD_OFFERS,
  SUBMIT_OFFER,
} from '../types/constants';
import { push } from 'connected-react-router';

function* requestOffer(action: RequestRecord): SagaIterator {
  let offer: Offer | undefined = undefined;
  if (typeof action.id === 'undefined') {
    offer = {
      id: 0,
      version: '',
      upload: '',
      state: OfferState.Draft,
      lastModified: new Date(),
      nettoPrice: {
        autoApproveReduction: 0,
        autoApproveReductionPercentage: 0,
        basic: 0,
        dealerPrice: 0,
        dealerPriceWithExtraReduction: 0,
        defaultReduction: 0,
        defaultReductionPercentage: 0,
        extraReduction: 0,
        extraReductionPercentage: 0,
        id: 0,
        options: 0,
        optionsColor: 0,
        optionsWheels: 0,
        priceWithOptions: 0,
        transportAndRecycle: 865,
      },
      pdfPrice: {
        actionReduction: 0,
        actionReductionPercentage: 0,
        calculatedReduction: 0,
        calculatedReductionPercentage: 0,
        customerPrice: 0,
        defaultPriceOptions: 0,
        defaultReduction: 0,
        defaultReductionPercentage: 0,
        grossAmountCatalogPrice: 0,
        id: 0,
        localExtra: 0,
        nettoPrice: 0,
        optionsCatalogPrice: 0,
        specialReduction: 0,
        specialReductionPercentage: 0,
        totalGrossAmount: 0,
        totalReduction: 0,
      },
      profitPrice: {
        id: 0,
        percentage: 0,
        profit: 0,
        salesPrice: 0,
      },
      optionalLocalWorks: [],
      inclusiveLocalWorks: [],
      recordId: 0,
      manCode: '',
      record: {
        axles: '',
        cabin: '',
        id: 0,
        numberOfTons: '',
        type: '',
        type2: '',
      },
    };
  } else {
    try {
      offer = yield call(api.getOffer, action.id);
    } catch {
      yield put(recordError());
    }
  }
  if (typeof offer !== 'undefined') {
    yield put(receiveOffer(offer));
  }
}

function* saveOffer(action: SubmitOffer): SagaIterator {
  const redirect = action.offer.id < 1;
  if (redirect) {
    if (typeof action.file !== 'undefined') {
      const offer: Offer = yield call(api.saveOffer, action.offer, action.file);
      yield put(receiveOffer(offer));
      yield put(push(`/records/${offer.recordId}/offer/${offer.id}`));
    } else {
      yield put(offerError());
    }
  } else {
    const offer: Offer = yield call(api.updateOffer, action.offer);
    yield put(receiveOffer(offer));
  }
}

function* priceCalculation(action: RequestPriceCalculation): SagaIterator {
  const abortController = new AbortController();
  try {
    const offer: Offer = yield call(api.offerPriceCalculation, abortController.signal, action.offer);
    yield put(receivePriceCalculation(offer));
  } catch (error) {
    alert(error);
    yield put(priceCalculationError());
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

function* getOffersByRecordId(action: RequestRecordOffers): SagaIterator {
  const offers = yield call(api.getRecordOffers, action.id);

  yield put(receiveRecordOffers(offers));
}

function* generateOffer(action: RequestGenerateOffer): SagaIterator {
  yield call(api.generateOffer, action.offer);
}

function* watchRequestOffer(): SagaIterator {
  yield takeLatest(REQUEST_OFFER, requestOffer);
}

function* watchSubmitOffer(): SagaIterator {
  yield takeLatest(SUBMIT_OFFER, saveOffer);
}

function* watchGenerateOffer(): SagaIterator {
  yield takeLatest(REQUEST_GENERATE_OFFER, generateOffer);
}

function* watchRequestPriceCalculation(): SagaIterator {
  yield takeLatest(REQUEST_PRICE_CALCULATION, priceCalculation);
}

function* watchRequestRecordOffers(): SagaIterator {
  yield takeLatest(REQUEST_RECORD_OFFERS, getOffersByRecordId);
}

export default function* offersSaga(): unknown {
  yield all([
    watchRequestOffer(),
    watchSubmitOffer(),
    watchRequestPriceCalculation(),
    watchGenerateOffer(),
    watchRequestRecordOffers(),
  ]);
}
