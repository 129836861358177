import { takeLatest, all, call, put, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { ReceiveRecord, RequestRecord, RequestRecordState, SubmitRecord, SubmitRecordState } from '../types/actions';
import { Record } from '../types/models';
import * as api from '../api';
import {
  receiveRecord,
  receiveRecords,
  receiveRecordState,
  recordError,
  recordsError,
  recordStateError,
  requestRecordOffers,
  requestRecordState,
} from '../actions';
import {
  RECEIVE_RECORD,
  REQUEST_RECORD,
  REQUEST_RECORDS,
  REQUEST_RECORD_STATE,
  SUBMIT_RECORD,
  SUBMIT_RECORD_STATE,
} from '../types/constants';
import { push } from 'connected-react-router';
import { RootState } from '../reducers';

function* requestRecord(action: RequestRecord): SagaIterator {
  let record: Record | undefined = undefined;
  if (typeof action.id === 'undefined') {
    const state: RootState = yield select();
    record = {
      id: 0,
      customer:
        typeof state.customers.customer === 'undefined'
          ? {
              addressLine: '',
              city: '',
              companyName: '',
              id: 0,
              postalCode: '',
              vat: '',
              records: [],
            }
          : state.customers.customer,
      offers: [],
      axles: '',
      cabin: '',
      numberOfTons: '',
      type: '',
      type2: '',
    };
  } else {
    try {
      record = yield call(api.getRecord, action.id);
    } catch {
      yield put(recordError());
    }
  }
  if (typeof record !== 'undefined') {
    yield put(receiveRecord(record));
  }
}

function* receiveRecordAction(action: ReceiveRecord): SagaIterator {
  if (action.record.id > 0) {
    yield put(requestRecordState(action.record.id));
    yield put(requestRecordOffers(action.record.id));
  }
}

function* requestRecordStateAction(action: RequestRecordState): SagaIterator {
  try {
    const state = yield call(api.getRecordState, action.id);
    yield put(receiveRecordState(state));
  } catch {
    yield put(recordStateError());
  }
}

function* saveRecordState(action: SubmitRecordState): SagaIterator {
  try {
    const request = action.state;
    console.log(request);
    const state = yield call(api.saveRecordState, request);
    yield put(receiveRecordState(state));
  } catch {
    yield put(recordStateError());
  }
}

function* getAllRecords(): SagaIterator {
  try {
    const records: Record[] = yield call(api.getAllRecords);
    yield put(receiveRecords(records));
  } catch (error) {
    console.error(error);
    yield put(recordsError());
  }
}

function* saveRecord(action: SubmitRecord): SagaIterator {
  const redirect = action.record.id < 1;
  if (redirect) {
    const record: Record = yield call(api.saveRecord, action.record);
    yield put(push(`/records/${record.id}`));
  } else {
    const record: Record = yield call(api.updateRecord, action.record);
    yield put(receiveRecord(record));
  }
}

function* watchRequestRecord(): SagaIterator {
  yield takeLatest(REQUEST_RECORD, requestRecord);
}

function* watchRequestRecordState(): SagaIterator {
  yield takeLatest(REQUEST_RECORD_STATE, requestRecordStateAction);
}

function* watchRequestRecords(): SagaIterator {
  yield takeLatest(REQUEST_RECORDS, getAllRecords);
}

function* watchSubmitRecord(): SagaIterator {
  yield takeLatest(SUBMIT_RECORD, saveRecord);
}

function* watchSubmitRecordState(): SagaIterator {
  yield takeLatest(SUBMIT_RECORD_STATE, saveRecordState);
}

function* watchReceiveRecord(): SagaIterator {
  yield takeLatest(RECEIVE_RECORD, receiveRecordAction);
}

export default function* recordsSaga(): unknown {
  yield all([
    watchRequestRecord(),
    watchRequestRecords(),
    watchSubmitRecord(),
    watchRequestRecordState(),
    watchSubmitRecordState(),
    watchReceiveRecord(),
  ]);
}
