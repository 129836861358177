import * as types from '../types/constants';
import * as models from '../types/models';
import { Actions } from '../types/actions';

export const requestRecordState = (id: number): Actions => ({
  type: types.REQUEST_RECORD_STATE,
  id,
});

export const receiveRecordState = (state: models.RecordStateHistory): Actions => ({
  type: types.RECEIVE_RECORD_STATE,
  state,
});

export const recordStateError = (): Actions => ({
  type: types.RECORD_STATE_ERROR,
});

export const submitRecordState = (state: models.RecordStateHistory): Actions => ({
  type: types.SUBMIT_RECORD_STATE,
  state,
});
