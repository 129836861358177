import * as models from '../types/models';
import callApi, { callGenerateApi, handleOfferApi, handleRecordApi } from './utils';

export async function getRecord(id: number): Promise<models.Models> {
  return await callApi(`/api/record/${id}`, 'GET');
}

export async function getOffer(id: number): Promise<models.Models> {
  return await callApi(`/api/offer/${id}`, 'GET');
}

export async function saveRecord(record: models.Record): Promise<models.Models> {
  return await callApi(`/api/record`, 'POST', record);
}

export async function updateRecord(record: models.Record): Promise<models.Models> {
  return await callApi(`/api/record/${record.id}`, 'PUT', record);
}

export async function getAllRecords(): Promise<models.Models> {
  return await callApi(`/api/record/all`, 'GET');
}

export async function saveOffer(offer: models.Offer, file: File): Promise<models.Models> {
  return await handleOfferApi(`/api/offer`, 'POST', offer, file);
}

export async function updateOffer(offer: models.Offer): Promise<models.Models> {
  return await callApi(`/api/offer/${offer.id}`, 'PUT', offer);
}

export async function offerPriceCalculation(signal: AbortSignal, offer: models.Offer): Promise<models.Models> {
  return await callApi(`/api/offer/price-calculation`, 'POST', offer, signal);
}

export async function generateOffer(offer: models.Offer): Promise<void> {
  return await callGenerateApi(`/api/generator/offer`, 'POST', offer);
}

export async function getAllLocalWorks(): Promise<models.Models> {
  return await callApi(`/api/local-work/all`, 'GET');
}

export async function getLocalWork(id: number): Promise<models.Models> {
  return await callApi(`/api/local-work/${id}`, 'GET');
}

export async function saveLocalWork(localWork: models.LocalWork): Promise<models.Models> {
  return await callApi(`/api/local-work`, 'POST', localWork);
}

export async function updateLocalWork(localWork: models.LocalWork): Promise<models.Models> {
  return await callApi(`/api/local-work/${localWork.id}`, 'PUT', localWork);
}

export async function searchCustomers(customer: models.Customer): Promise<models.Models> {
  return await callApi(`/api/customer/search`, 'POST', customer);
}

export async function getCustomer(id: number): Promise<models.Models> {
  return await callApi(`/api/customer/${id}`, 'GET');
}

export async function getAllCustomers(): Promise<models.Models> {
  return await callApi(`/api/customer/all`, 'GET');
}

export async function saveCustomer(customer: models.Customer): Promise<models.Models> {
  return await callApi(`/api/customer`, 'POST', customer);
}

export async function updateCustomer(customer: models.Customer): Promise<models.Models> {
  return await callApi(`/api/customer/${customer.id}`, 'PUT', customer);
}

export async function getRecordState(recordId: number): Promise<models.Models> {
  return await callApi(`/api/record/${recordId}/state`, 'GET');
}

export async function saveRecordState(recordState: models.RecordStateHistory): Promise<models.Models> {
  return await callApi(`/api/record-state`, 'POST', recordState);
}

export async function getRecordOffers(recordId: number): Promise<models.Models> {
  return await callApi(`/api/record/${recordId}/offers`, 'GET');
}

export async function saveModel(model: models.Model, file: File): Promise<models.Models> {
  return await handleRecordApi(`/api/model`, 'POST', model, file);
}
