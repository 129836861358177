import { RequestCustomer, RequestCustomerSearch, SubmitCustomer } from '../types/actions';
import { takeLatest, all, put, call, cancelled, delay } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import * as api from '../api';
import { Customer } from '../types/models';
import { REQUEST_CUSTOMER, REQUEST_CUSTOMERS, REQUEST_CUSTOMER_SEARCH, SUBMIT_CUSTOMER } from '../types/constants';
import {
  customerError,
  customersError,
  receiveCustomer,
  receiveCustomers,
  receiveCustomerSearch,
  receiveCustomersSearch,
} from '../actions';
import { push } from 'connected-react-router';

function* requestCustomer(action: RequestCustomer): SagaIterator {
  let customer: Customer | undefined = undefined;
  if (typeof action.id === 'undefined') {
    customer = {
      id: 0,
      addressLine: '',
      city: '',
      companyName: '',
      postalCode: '',
      vat: '',
      records: [],
    };
  } else {
    try {
      customer = yield call(api.getCustomer, action.id);
    } catch {
      yield put(customerError());
    }
  }
  if (typeof customer !== 'undefined') {
    yield put(receiveCustomer(customer));
  }
}

function* getAllCustomers(): SagaIterator {
  try {
    const customers: Customer[] = yield call(api.getAllCustomers);
    yield put(receiveCustomers(customers));
  } catch (error) {
    console.error(error);
    yield put(customersError());
  }
}

function* saveCustomer(action: SubmitCustomer): SagaIterator {
  const redirect = action.customer.id < 1;
  if (redirect) {
    const customer: Customer = yield call(api.saveCustomer, action.customer);
    yield put(push(`/customers/${customer.id}`));
  } else {
    const customer: Customer = yield call(api.updateCustomer, action.customer);
    yield put(receiveCustomer(customer));
  }
}

function* searchCustomers(action: RequestCustomerSearch): SagaIterator {
  try {
    yield delay(1000);
    const customers: Customer[] = yield call(api.searchCustomers, action.customer);
    if (customers !== null) {
      if (customers.length === 1) {
        yield put(receiveCustomerSearch(customers[0]));
      }
      yield put(receiveCustomersSearch(customers));
    }
  } catch (error) {
    console.error(error);
  } finally {
    if (cancelled()) {
      console.log('cancelled');
    }
  }
}

function* watchRequestCustomer(): SagaIterator {
  yield takeLatest(REQUEST_CUSTOMER, requestCustomer);
}

function* watchRequestCustomers(): SagaIterator {
  yield takeLatest(REQUEST_CUSTOMERS, getAllCustomers);
}

function* watchSubmit(): SagaIterator {
  yield takeLatest(SUBMIT_CUSTOMER, saveCustomer);
}

function* watchSearchCustomers(): SagaIterator {
  yield takeLatest(REQUEST_CUSTOMER_SEARCH, searchCustomers);
}

export default function* customersSaga(): unknown {
  yield all([watchRequestCustomer(), watchRequestCustomers(), watchSubmit(), watchSearchCustomers()]);
}
