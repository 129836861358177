import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useBetraconSelector } from '../../reducers';
import Modal from '@duik/modal';
import { Table } from 'react-bootstrap';
import { LocalWork } from '../../types/models';
import Toggle from '@duik/toggle';
import ScrollArea from '@duik/scroll-area';
import Button from '@duik/button';
import Icon from '@duik/icon';
import TextField from '@duik/text-field';
import { pushIncludedOfferLocalWork, pushOptionalOfferLocalWork, removeIncludedOfferLocalWork, removeOptionalOfferLocalWork } from '../../actions';
import _ from 'lodash';
import { ContainerHorizontal } from '@duik/it';

type Props = {
  isOpen: boolean;
  handleClose: () => void;
};

const LocalWorksModal: FC<Props> = (props) => {
  const { isOpen, handleClose } = props;
  const dispatch = useDispatch();
  const state = useBetraconSelector((state) => state);
  const {
    offers: { offer },
    localWorks: { localWorks },
  } = state;
  const [typeaheadValue, setTypeaheadValue] = useState('');
  const [chosenTypeaheadValue, setChosenTypeaheadValue] = useState('');

  const handleTypeaheadChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setTypeaheadValue(e.target.value);
  };

  const handleChosenTypeaheadChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setChosenTypeaheadValue(e.target.value);
  };

  const getFilteredList = (): LocalWork[] => {
    return localWorks.filter((f) => f.name.toLowerCase().indexOf(typeaheadValue.toLowerCase()) > -1);
  };

  const getFilteredChosenList = (): LocalWork[] => {
    const result = _.concat((offer?.inclusiveLocalWorks ?? []), (offer?.optionalLocalWorks ?? []));

    return result.filter(
      (f) => f.name.toLowerCase().indexOf(chosenTypeaheadValue.toLowerCase()) > -1,
    );
  };

  const offerHasRequiredLocalWork = (localWork: LocalWork): boolean => {
    return _.some(offer?.inclusiveLocalWorks ?? [], (l) => l.id === localWork.id)
  };

  const offerHasOptionalLocalWork = (localWork: LocalWork): boolean => {
    return _.some(offer?.optionalLocalWorks ?? [], (l) => l.id === localWork.id)
  };

  const handleOfferToggle = (localWork: LocalWork): void => {
    const action = offerHasRequiredLocalWork(localWork)
      ? removeIncludedOfferLocalWork(localWork)
      : pushIncludedOfferLocalWork(localWork);
    dispatch(action);
  };

  const handleOptionalToggle = (localWork: LocalWork): void => {
    const action = offerHasOptionalLocalWork(localWork)
      ? removeOptionalOfferLocalWork(localWork)
      : pushOptionalOfferLocalWork(localWork);
    dispatch(action);
  };

  const getChosenLocalWorkType = (localWork: LocalWork): string => offerHasOptionalLocalWork(localWork) ? "Optioneel" : "Verplicht"

  return (
    <Modal handleClose={handleClose} isOpen={isOpen} full closeOnOuterClick>
      <Modal.Header>
        <Modal.Title>Lokale werken beheren</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ContainerHorizontal style={{ justifyContent: 'space-between' }}>
          <div style={{ width: '60%', paddingRight: '50px' }}>
            <h3>Lijst met lokale werken</h3>
            <div style={{ width: '100%' }}>
              <TextField
                placeholder={`Typ hier de naam van het lokaal werk`}
                onChange={handleTypeaheadChange}
                value={typeaheadValue}
                rightEl={<Icon>search_left</Icon>}
              />
            </div>
            <div style={{ height: 'calc(100% - 60px)' }}>
              <ScrollArea>
                <Table striped bordered size="sm">
                  <thead>
                    <tr>
                      <th>Inclusief</th>
                      <th>Optioneel</th>
                      <th>Naam</th>
                      <th>Prijs</th>
                    </tr>
                  </thead>
                  <tbody>
                    {getFilteredList().map((localWork, key) => (
                      <tr key={`local-work-${key}`}>
                        <td>
                          <Toggle
                            name={`local-work-${key}`}
                            onChange={(): void => handleOfferToggle(localWork)}
                            checked={offerHasRequiredLocalWork(localWork)}
                            disabled={offerHasOptionalLocalWork(localWork)}
                          />
                        </td>
                        <td>
                          <Toggle
                            name={`optional-local-work-${key}`}
                            onChange={(): void => handleOptionalToggle(localWork)}
                            checked={offerHasOptionalLocalWork(localWork)}
                            disabled={offerHasRequiredLocalWork(localWork)}
                          />
                        </td>
                        <td>
                          <Button
                            style={{ border: 'none', width: '100%' }}
                            onClick={(): void => offerHasOptionalLocalWork(localWork) ? handleOptionalToggle(localWork) : handleOfferToggle(localWork)}
                            transparent
                            noBorder={true}
                          >
                            {localWork.name}
                          </Button>
                        </td>
                        <td dangerouslySetInnerHTML={{ __html: `&euro; ${localWork.price}` }} />
                      </tr>
                    ))}
                    <tr>
                      <td colSpan={2}>
                        <Button primary>Toevoegen</Button>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </ScrollArea>
            </div>
          </div>
          <div style={{ width: '40%' }}>
            <h3>Gekozen lokale werken</h3>
            <div style={{ width: '100%' }}>
              <TextField
                placeholder={`Typ hier de naam van het lokaal werk`}
                onChange={handleChosenTypeaheadChange}
                value={chosenTypeaheadValue}
                rightEl={<Icon>search_left</Icon>}
              />
            </div>
            <div style={{ height: 'calc(100% - 60px)' }}>
              <ScrollArea>
                <Table striped bordered size="sm">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Type</th>
                      <th>Naam</th>
                      <th>Prijs</th>
                    </tr>
                  </thead>
                  <tbody>
                    {_.orderBy(getFilteredChosenList(), ['id']).map((localWork, key) => (
                      <tr key={`offer-local-work-${key}`}>
                        <td>
                          <Button
                            square
                            transparent
                            onClick={(): void => {
                              dispatch(removeIncludedOfferLocalWork(localWork));
                            }}
                          >
                            <Icon>close</Icon>
                          </Button>
                        </td>
                        <td>{getChosenLocalWorkType(localWork)}</td>
                        <td dangerouslySetInnerHTML={{ __html: localWork.name }} />
                        <td dangerouslySetInnerHTML={{ __html: `&euro; ${localWork.price}` }} />
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </ScrollArea>
            </div>
          </div>
        </ContainerHorizontal>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose} primary>
          Sluiten
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LocalWorksModal;
