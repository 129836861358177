import * as types from '../types/constants';
import * as models from '../types/models';
import { Actions } from '../types/actions';

export const requestRecordOffers = (id: number): Actions => ({
  type: types.REQUEST_RECORD_OFFERS,
  id,
});

export const receiveRecordOffers = (offers: models.Offer[]): Actions => ({
  type: types.RECEIVE_RECORD_OFFERS,
  offers,
});

export const requestOffer = (id?: number): Actions => ({
  type: types.REQUEST_OFFER,
  id,
});

export const receiveOffer = (offer: models.Offer): Actions => ({
  type: types.RECEIVE_OFFER,
  offer,
});

export const offerError = (): Actions => ({
  type: types.OFFER_ERROR,
});

export const submitOffer = (offer: models.Offer, file?: File): Actions => ({
  type: types.SUBMIT_OFFER,
  offer,
  file,
});

export const requestGenerateOffer = (offer: models.Offer): Actions => ({
  type: types.REQUEST_GENERATE_OFFER,
  offer,
});
