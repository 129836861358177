import * as types from '../types/constants';
import * as models from '../types/models';
import { Actions } from '../types/actions';

export const requestLocalWorks = (): Actions => ({
  type: types.REQUEST_LOCAL_WORKS,
});

export const receiveLocalWorks = (localWorks: models.LocalWork[]): Actions => ({
  type: types.RECEIVE_LOCAL_WORKS,
  localWorks,
});

export const localWorksError = (): Actions => ({
  type: types.LOCAL_WORKS_ERROR,
});

export const pushIncludedOfferLocalWork = (localWork: models.LocalWork): Actions => ({
  type: types.PUSH_INCLUDED_OFFER_LOCAL_WORK,
  localWork,
});

export const removeIncludedOfferLocalWork = (localWork: models.LocalWork): Actions => ({
  type: types.REMOVE_INCLUDED_OFFER_LOCAL_WORK,
  localWork,
});

export const pushOptionalOfferLocalWork = (localWork: models.LocalWork): Actions => ({
  type: types.PUSH_OPTIONAL_OFFER_LOCAL_WORK,
  localWork,
});

export const removeOptionalOfferLocalWork = (localWork: models.LocalWork): Actions => ({
  type: types.REMOVE_OPTIONAL_OFFER_LOCAL_WORK,
  localWork,
});

export const requestLocalWork = (id?: number): Actions => ({
  type: types.REQUEST_LOCAL_WORK,
  id,
});

export const receiveLocalWork = (localWork: models.LocalWork): Actions => ({
  type: types.RECEIVE_LOCAL_WORK,
  localWork,
});

export const localWorkError = (): Actions => ({
  type: types.LOCAL_WORK_ERROR,
});

export const submitLocalWork = (localWork: models.LocalWork): Actions => ({
  type: types.SUBMIT_LOCAL_WORK,
  localWork,
});
