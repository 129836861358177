export type Base = {
  id: number;
};

export enum OfferState {
  Draft = 1,
  Sent = 2,
  Cancelled = 3,
  Removed = 4,
}

export enum RecordState {
  Pending = 0,
  Won = 1,
  Lost = 2,
}

export type Customer = Base & {
  companyName: string;
  vat: string;
  addressLine: string;
  postalCode: string;
  city: string;
  records?: RecordListItem[];
};

export type ListItem = Base & {
  name: string;
};

export type LocalWork = Base & {
  name: string;
  price: number;
};

export type NettoPrice = Base & {
  basic: number;
  options: number;
  optionsWheels: number;
  optionsColor: number;
  priceWithOptions: number;
  defaultReductionPercentage: number;
  defaultReduction: number;
  autoApproveReductionPercentage: number;
  autoApproveReduction: number;
  extraReductionPercentage: number;
  extraReduction: number;
  transportAndRecycle: number;
  dealerPrice: number;
  dealerPriceWithExtraReduction: number;
};

export type Offer = Base & {
  version: string;
  upload: string;
  pdfPrice: PdfPrice;
  nettoPrice: NettoPrice;
  profitPrice: ProfitPrice;
  inclusiveLocalWorks: LocalWork[];
  optionalLocalWorks: LocalWork[];
  recordId: number;
  manCode: string;
  state: OfferState;
  record: RecordConfiguration;
  lastModified: Date;
};

export type PdfPrice = Base & {
  grossAmountCatalogPrice: number;
  optionsCatalogPrice: number;
  defaultReductionPercentage: number;
  defaultReduction: number;
  calculatedReductionPercentage: number;
  calculatedReduction: number;
  specialReductionPercentage: number;
  specialReduction: number;
  actionReductionPercentage: number;
  actionReduction: number;
  localExtra: number;
  defaultPriceOptions: number;
  nettoPrice: number;
  totalGrossAmount: number;
  totalReduction: number;
  customerPrice: number;
};

export type ProfitPrice = Base & {
  salesPrice: number;
  profit: number;
  percentage: number;
};

export type RecordConfiguration = Base & {
  type: string;
  numberOfTons: string;
  axles: string;
  type2: string;
  cabin: string;
};

export type RecordListItem = RecordConfiguration & {
  state?: RecordStateHistory;
};

export type Record = RecordListItem & {
  offers: Offer[];
  customer: Customer;
};

export type RecordStateHistory = Base & {
  recordId: number;
  state: RecordState;
  created: Date;
  reason: string;
};

export type Model = {
  offer: Offer;
  customer: Customer;
  record: Record;
};

export type Models =
  | Record
  | Record[]
  | ProfitPrice
  | PdfPrice
  | Offer
  | NettoPrice
  | LocalWork
  | ListItem
  | ListItem[]
  | Customer
  | RecordStateHistory;
