import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  removeIncludedOfferLocalWork,
  removeOptionalOfferLocalWork,
  requestGenerateOffer,
  requestLocalWorks,
  requestOffer,
  requestPriceCalculation,
  requestRecord,
  submitOffer,
} from '../../actions';
import { useBetraconSelector } from '../../reducers';
import * as yup from 'yup';
import { useFormik } from 'formik';
import ContainerVertical from '@duik/container-vertical';
import TextField from '@duik/text-field';
import FormGroupContainer from '@duik/form-group-container';
import FormGroup from '@duik/form-group';
import ButtonGroup from '@duik/button-group';
import Divider from '@duik/divider';
import { Button } from '@duik/it';
import { useParams } from 'react-router-dom';
import { push } from 'connected-react-router';
import UploadSection from '../../components/UploadSection';
import { LocalWork, Offer, OfferState } from '../../types/models';
import LocalWorksModal from './LocalWorksModal';
import { Table } from 'react-bootstrap';
import Icon from '@duik/icon';
import _ from 'lodash';

import Loader from '../../components/Loader';

type OfferParams = {
  id?: string;
  offerId?: string;
};

const Edit: FC = () => {
  const dispatch = useDispatch();
  const state = useBetraconSelector((state) => state);
  const { id, offerId } = useParams<OfferParams>();
  const {
    offers: { offer, loading },
    records: { record },
  } = state;
  const [file, setFile] = useState<File>();
  const [offerState, setOfferState] = useState<OfferState>(OfferState.Draft);
  const [isInclusiveLocalWorksOpen, setInclusiveLocalWorksOpen] = useState<boolean>(false);
  const [version, setVersion] = useState('');

  useEffect((): void => {
    if (typeof offerId === 'undefined' || offerId === 'add') {
      dispatch(requestOffer());
    } else {
      dispatch(requestOffer(parseInt(offerId)));
    }
  }, [dispatch, offerId]);

  useEffect((): void => {
    if (typeof record === 'undefined' && typeof id !== 'undefined') {
      dispatch(requestRecord(parseInt(id)));
    }
  }, [dispatch, id, record]);

  useEffect((): void => {
    setOfferState(offer?.state ?? OfferState.Draft);
  }, [offer]);

  useEffect((): void => {
    dispatch(requestLocalWorks());
  }, [dispatch]);

  useEffect((): void => {
    if (offer !== null && record?.offers !== null) {
      setVersion(
        (offer?.version ?? `version ${(record?.offers?.length ?? 0) + 1}`).trim().length > 0
          ? offer?.version ?? `version ${(record?.offers?.length ?? 0) + 1}`
          : `version ${(record?.offers?.length ?? 0) + 1}`,
      );
    }
  }, [offer, record]);

  const handlePercentage = (input: number): string => {
    return `${(input * 100).toFixed(2)}%`;
  };

  const validation = yup.object({
    version: yup.string().trim().required('Versie is een verplicht veld'),
    grossAmountCatalogPrice: yup
      .number()
      .min(0, 'Bruto catalogusprijs moet groter zijn dan 0')
      .required('Bruto catalogusprijs is verplicht'),
    optionsCatalogPrice: yup.number().min(0, 'Somopties moet groter zijn dan 0').required('Somopties is verplicht'),
    autoApproveReductionPercentage: yup.number().required('Auto approve korting - percentage is een verplicht veld'),
    autoApproveReduction: yup.number().required('Auto approve is een verplicht veld'),
    extraReductionPercentage: yup.number().required('Extra korting - percentage is een verplicht veld'),
    extraReduction: yup.number().required('Extra korting is een verplicht veld'),
    transportAndRecycle: yup
      .number()
      .min(0, 'Transport en recyclage kosten moet groter zijn dan 0')
      .required('Transport en recyclage kosten is verplicht'),
    salesPrice: yup.number().min(0, 'Verkoopprijs moet groter zijn dan 0').required('Verkoopprijs is verplicht'),
  });

  type OfferForm = yup.InferType<typeof validation>;

  const mapOfferValues = useCallback(
    (values: OfferForm): Offer => {
      const pdfPrice = { ...offer?.pdfPrice };
      pdfPrice.grossAmountCatalogPrice = values?.grossAmountCatalogPrice ?? 0;
      pdfPrice.optionsCatalogPrice = values?.optionsCatalogPrice ?? 0;

      const nettoPrice = { ...offer?.nettoPrice };
      nettoPrice.autoApproveReductionPercentage = (values?.autoApproveReductionPercentage ?? 0) / 100;
      nettoPrice.autoApproveReduction = values?.autoApproveReduction ?? 0;
      nettoPrice.extraReductionPercentage = (values?.extraReductionPercentage ?? 0) / 100;
      nettoPrice.extraReduction = values?.extraReduction ?? 0;
      nettoPrice.transportAndRecycle = values?.transportAndRecycle ?? 0;

      const profitPrice = { ...offer?.profitPrice };
      profitPrice.salesPrice = values?.salesPrice ?? 0;
      return Object.assign({}, offer, {
        version: values?.version ?? `version${(record?.offers?.length ?? 0) + 1}`,
        recordId: parseInt(id ?? '0'),
        pdfPrice: pdfPrice,
        nettoPrice: nettoPrice,
        profitPrice: profitPrice,
        record: record,
      });
    },
    [id, offer, record],
  );

  const handleOfferStateChange = (offerState: OfferState): void => {
    if (offerState === OfferState.Draft && offer?.state !== OfferState.Draft) {
      return;
    }

    if (offer?.state === OfferState.Cancelled) {
      return;
    }

    setOfferState(offerState);
  };

  const formik = useFormik<OfferForm>({
    initialValues: {
      version: version,
      grossAmountCatalogPrice: offer?.pdfPrice?.grossAmountCatalogPrice ?? 0,
      optionsCatalogPrice: offer?.pdfPrice?.optionsCatalogPrice ?? 0,
      autoApproveReductionPercentage: (offer?.nettoPrice?.autoApproveReductionPercentage ?? 0) * 100,
      autoApproveReduction: offer?.nettoPrice?.autoApproveReduction ?? 0,
      extraReductionPercentage: (offer?.nettoPrice?.extraReductionPercentage ?? 0) * 100,
      extraReduction: offer?.nettoPrice?.extraReduction ?? 0,
      transportAndRecycle: offer?.nettoPrice?.transportAndRecycle ?? 865,
      salesPrice: offer?.profitPrice?.salesPrice ?? 0,
    },
    validationSchema: validation,
    onSubmit: (values) => {
      if (typeof offer !== 'undefined') {
        const request = mapOfferValues(values);
        request.state = offerState;
        dispatch(submitOffer(request, file));
      }
    },
    enableReinitialize: true,
  });

  const handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    formik.handleChange(event);
  };

  const handleCancelButton = (): void => {
    dispatch(requestOffer());
    dispatch(push(`/records/${id}`));
  };

  useEffect((): void => {
    const hasEmptyValue =
      formik.values.grossAmountCatalogPrice.toString().trim().length === 0 ||
      formik.values.autoApproveReduction.toString().trim().length === 0 ||
      formik.values.autoApproveReductionPercentage.toString().trim().length === 0 ||
      formik.values.extraReduction.toString().trim().length === 0 ||
      formik.values.extraReductionPercentage.toString().trim().length === 0 ||
      formik.values.optionsCatalogPrice.toString().trim().length === 0 ||
      formik.values.salesPrice.toString().trim().length === 0 ||
      formik.values.transportAndRecycle.toString().trim().length === 0;

    if (typeof record !== 'undefined' && record !== null && formik.isValid && !hasEmptyValue) {
      dispatch(requestPriceCalculation(mapOfferValues(formik.values)));
    }
  }, [record, formik.values, formik.isValid, dispatch, mapOfferValues]);

  const handleGenerateOfferClick = (): void => {
    dispatch(requestGenerateOffer(mapOfferValues(formik.values)));
  };

  const getFilteredChosenList = (): LocalWork[] => {
    return (offer?.inclusiveLocalWorks ?? []).filter((f) => true);
  };

  const getFilteredOptionalList = (): LocalWork[] => {
    return (offer?.optionalLocalWorks ?? []).filter((f) => true);
  };
  return (
    <ContainerVertical>
      <div className="container">
        <h1>Offerte</h1>
          {typeof offer === 'undefined' || offer == null || loading ? (
            <Loader />
          ) : (
              <form onSubmit={formik.handleSubmit}>
                <FormGroupContainer>
                  <FormGroupContainer>
                    <h3>Configuratie</h3>
                  </FormGroupContainer>
                  <FormGroupContainer horizontal>
                    <TextField label="Type" value={record?.type} disabled />
                    <TextField label="Tonnage" value={record?.numberOfTons} disabled />
                    <TextField label="Assen" value={record?.axles} disabled />
                    <TextField label="Type2" value={record?.type2} disabled />
                    <TextField label="Cabine" value={record?.cabin} disabled />
                    <FormGroup>
                      {typeof offer.manCode !== 'undefined' && offer.manCode !== null && offer.manCode.length > 0 ? (
                        <TextField name="manCode" id="manCode" label="MAN code" value={offer.manCode} disabled />
                      ) : (
                        <UploadSection
                          accept=".pdf"
                          containsFile={typeof file !== 'undefined'}
                          uploadText={`Bestand uploaden`}
                          modifier="pdf"
                          handleFileChange={(files): void => {
                            if (files && files.length > 0) {
                              setFile(files[0]);
                            }
                          }}
                        />
                      )}
                    </FormGroup>
                  </FormGroupContainer>
                </FormGroupContainer>
                <Divider margin />

                <FormGroupContainer horizontal>
                  <FormGroupContainer className="form-group-container-border">
                    <h3>C4S - Top</h3>

                    {offer.state === OfferState.Draft ? (
                      <TextField
                        leftEl={<span dangerouslySetInnerHTML={{ __html: '&euro;' }} />}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        id="grossAmountCatalogPrice"
                        name="grossAmountCatalogPrice"
                        label="Bruto catalogusprijs"
                        type="number"
                        value={formik.values.grossAmountCatalogPrice}
                        errorMessage={
                          formik.touched.grossAmountCatalogPrice &&
                          Boolean(formik.errors.grossAmountCatalogPrice) &&
                          formik.errors.grossAmountCatalogPrice
                        }
                        disabled={offer.state !== OfferState.Draft}
                      />
                    ) : (
                      <TextField
                        label="Bruto catalogusprijs"
                        value={`€ ${offer.pdfPrice.grossAmountCatalogPrice}`}
                        disabled
                      />
                    )}
                    {offer.state === OfferState.Draft ? (
                      <TextField
                        leftEl={<span dangerouslySetInnerHTML={{ __html: '&euro;' }} />}
                        onChange={handlePriceChange}
                        onBlur={formik.handleBlur}
                        id="optionsCatalogPrice"
                        name="optionsCatalogPrice"
                        label="Somopties - onderdeel van de grote catalogusprijs"
                        type="number"
                        value={formik.values.optionsCatalogPrice}
                        errorMessage={
                          formik.touched.optionsCatalogPrice &&
                          Boolean(formik.errors.optionsCatalogPrice) &&
                          formik.errors.optionsCatalogPrice
                        }
                        disabled={offer.state !== OfferState.Draft}
                      />
                    ) : (
                      <TextField
                        label="Somopties - onderdeel van de grote catalogusprijs"
                        value={`€ ${offer.pdfPrice.optionsCatalogPrice}`}
                        disabled
                      />
                    )}

                    <FormGroupContainer horizontal>
                      <TextField
                        label="Standaardkorting percentage"
                        value={handlePercentage(offer.pdfPrice.defaultReductionPercentage)}
                        disabled
                      />
                      <TextField label="Standaardkorting" value={`€ ${offer.pdfPrice.defaultReduction}`} disabled />
                    </FormGroupContainer>

                    <FormGroupContainer horizontal>
                      <TextField
                        label="Berekende korting percentage"
                        value={handlePercentage(offer.pdfPrice.calculatedReductionPercentage)}
                        disabled
                      />
                      <TextField label="Berekende korting" value={`€ ${offer.pdfPrice.calculatedReduction}`} disabled />
                    </FormGroupContainer>

                    <FormGroupContainer horizontal>
                      <TextField
                        label="Speciale korting percentage"
                        value={handlePercentage(offer.pdfPrice.specialReductionPercentage)}
                        disabled
                      />
                      <TextField label="Speciale korting" value={`€ ${offer.pdfPrice.specialReduction}`} disabled />
                    </FormGroupContainer>

                    <FormGroupContainer horizontal>
                      <TextField
                        label="Actiekorting percentage"
                        value={handlePercentage(offer.pdfPrice.actionReductionPercentage)}
                        disabled
                      />
                      <TextField label="Actiekorting" value={`€ ${offer.pdfPrice.actionReduction}`} disabled />
                    </FormGroupContainer>

                    <TextField label="Lokale extra uitrustingen" value={`€ ${offer.pdfPrice.localExtra}`} disabled />
                    <TextField label="Vasteprijsopties" value={`€ ${offer.pdfPrice.defaultPriceOptions}`} disabled />
                    <TextField label="Nettoprijs" value={`€ ${offer.pdfPrice.nettoPrice}`} disabled />
                    <TextField label="Totaal bruto" value={`€ ${offer.pdfPrice.totalGrossAmount}`} disabled />
                    <TextField label="Totale korting" value={`€ ${offer.pdfPrice.totalReduction}`} disabled />
                    <TextField label="Eindprijs klant" value={`€ ${offer.pdfPrice.customerPrice}`} disabled />
                  </FormGroupContainer>
                  <FormGroupContainer className="form-group-container-border">
                    <h3>Nettoberekening</h3>
                    <TextField label="Basisprijs BLP" className="text-input-highlight" value={`€ ${offer.nettoPrice.basic}`} disabled />
                    <TextField label="Opties" className="text-input-highlight" value={`€ ${offer.nettoPrice.options}`} disabled />
                    <TextField label="Opties (Banden)" value={`€ ${offer.nettoPrice.optionsWheels}`} disabled />
                    <TextField label="Opties (Kleur)" value={`€ ${offer.nettoPrice.optionsColor}`} disabled />
                    <TextField label="BLP incl. opties" value={`€ ${offer.nettoPrice.priceWithOptions}`} disabled />
                    <FormGroupContainer horizontal>
                      <TextField
                        label="Standaardkorting percentage"
                        value={handlePercentage(offer.nettoPrice.defaultReductionPercentage)}
                        disabled
                      />
                      <TextField label="Standaardkorting" value={`€ ${offer.nettoPrice.defaultReduction}`} disabled />
                    </FormGroupContainer>

                    <FormGroupContainer horizontal>
                      {offer.state === OfferState.Draft ? (
                        <TextField
                          rightEl={<span dangerouslySetInnerHTML={{ __html: '%' }} />}
                          onChange={handlePriceChange}
                          disabled={offer.state !== OfferState.Draft}
                          onBlur={formik.handleBlur}
                          id="autoApproveReductionPercentage"
                          name="autoApproveReductionPercentage"
                          label="Korting - auto approve percentage"
                          type="number"
                          value={formik.values.autoApproveReductionPercentage}
                          errorMessage={
                            formik.touched.autoApproveReductionPercentage &&
                            Boolean(formik.errors.autoApproveReductionPercentage) &&
                            formik.errors.autoApproveReductionPercentage
                          }
                        />
                      ) : (
                        <TextField
                          label="Korting - auto approve percentage"
                          value={`${offer.nettoPrice.autoApproveReductionPercentage} %`}
                          disabled
                        />
                      )}

                      {offer.state === OfferState.Draft ? (
                        <TextField
                          leftEl={<span dangerouslySetInnerHTML={{ __html: '&euro;' }} />}
                          onChange={handlePriceChange}
                          disabled={offer.state !== OfferState.Draft}
                          onBlur={formik.handleBlur}
                          id="autoApproveReduction"
                          name="autoApproveReduction"
                          label="Korting - auto approve"
                          type="number"
                          value={formik.values.autoApproveReduction}
                          errorMessage={
                            formik.touched.autoApproveReduction &&
                            Boolean(formik.errors.autoApproveReduction) &&
                            formik.errors.autoApproveReduction
                          }
                        />
                      ) : (
                        <TextField
                          label="Korting - auto approve"
                          value={`€ ${offer.nettoPrice.autoApproveReduction}`}
                          disabled
                        />
                      )}
                    </FormGroupContainer>

                    <FormGroupContainer horizontal>
                      {offer.state === OfferState.Draft ? (
                        <TextField
                          rightEl={<span dangerouslySetInnerHTML={{ __html: '%' }} />}
                          onChange={handlePriceChange}
                          onBlur={formik.handleBlur}
                          id="extraReductionPercentage"
                          name="extraReductionPercentage"
                          label="Extra tussenkomst percentage"
                          type="number"
                          value={formik.values.extraReductionPercentage}
                          errorMessage={
                            formik.touched.extraReductionPercentage &&
                            Boolean(formik.errors.extraReductionPercentage) &&
                            formik.errors.extraReductionPercentage
                          }
                          disabled={offer.state !== OfferState.Draft}
                        />
                      ) : (
                        <TextField
                          label="Extra tussenkomst percentage"
                          value={`${offer.nettoPrice.extraReductionPercentage} %`}
                          disabled
                        />
                      )}

                      {offer.state === OfferState.Draft ? (
                        <TextField
                          leftEl={<span dangerouslySetInnerHTML={{ __html: '&euro;' }} />}
                          onChange={handlePriceChange}
                          onBlur={formik.handleBlur}
                          id="extraReduction"
                          name="extraReduction"
                          label="Extra tussenkomst"
                          type="number"
                          value={formik.values.extraReduction}
                          errorMessage={
                            formik.touched.extraReduction &&
                            Boolean(formik.errors.extraReduction) &&
                            formik.errors.extraReduction
                          }
                          disabled={offer.state !== OfferState.Draft}
                        />
                      ) : (
                        <TextField label="Extra tussenkomst" value={`€ ${offer.nettoPrice.extraReduction}`} disabled />
                      )}
                    </FormGroupContainer>

                    <TextField
                      label="Transport en recyclage kosten"
                      value={`€ ${offer.nettoPrice.transportAndRecycle}`}
                      disabled
                    />
                    <TextField
                      label="Dealer Nettoprijs - Price Generator"
                      value={`€ ${offer.nettoPrice.dealerPrice}`}
                      disabled
                    />
                    <TextField
                      label="Dealer Nettoprijs - Extra tussenkomt"
                      value={`€ ${offer.nettoPrice.dealerPriceWithExtraReduction}`}
                      disabled
                    />
                  </FormGroupContainer>
                  <FormGroupContainer className="form-group-container-border">
                    <h3>Marge berekening</h3>
                    {offer.state === OfferState.Draft ? (
                      <TextField
                        leftEl={<span dangerouslySetInnerHTML={{ __html: '&euro;' }} />}
                        onChange={handlePriceChange}
                        onBlur={formik.handleBlur}
                        id="salesPrice"
                        name="salesPrice"
                        label="Verkoopsprijs"
                        type="number"
                        value={formik.values.salesPrice}
                        errorMessage={
                          formik.touched.salesPrice && Boolean(formik.errors.salesPrice) && formik.errors.salesPrice
                        }
                        disabled={offer.state !== OfferState.Draft}
                      />
                    ) : (
                      <TextField label="Verkoopsprijs" value={`€ ${offer.profitPrice.salesPrice}`} disabled />
                    )}
                    <FormGroupContainer horizontal>
                      <TextField
                        label="Marge percentage"
                        value={handlePercentage(offer.profitPrice.percentage)}
                        disabled
                      />
                      <TextField label="Marge" value={`€ ${offer.profitPrice.profit}`} disabled />
                    </FormGroupContainer>
                    <Divider margin />
                    <h3>Inclusieve Lokale werken</h3>
                    <Table responsive striped bordered size="sm">
                      <thead>
                        <tr>
                          {offer.state === OfferState.Draft && <th></th>}
                          <th>Naam</th>
                        </tr>
                      </thead>
                      <tbody>
                        {_.orderBy(getFilteredChosenList(), ['id']).map((localWork, key) => (
                          <tr key={`offer-local-work-${key}`}>
                            {offer.state === OfferState.Draft && (
                              <td>
                                <Button
                                  square
                                  transparent
                                  onClick={(): void => {
                                    dispatch(removeIncludedOfferLocalWork(localWork));
                                  }}
                                >
                                  <Icon>close</Icon>
                                </Button>
                              </td>
                            )}
                            <td dangerouslySetInnerHTML={{ __html: localWork.name }} />
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <Divider margin />
                    <h3>Optionele Lokale werken</h3>
                    <Table responsive striped bordered size="sm">
                      <thead>
                        <tr>
                          {offer.state === OfferState.Draft && <th></th>}
                          <th>Naam</th>
                        </tr>
                      </thead>
                      <tbody>
                        {_.orderBy(getFilteredOptionalList(), ['id']).map((localWork, key) => (
                          <tr key={`offer-optional-local-work-${key}`}>
                            {offer.state === OfferState.Draft && (
                              <td>
                                <Button
                                  square
                                  transparent
                                  onClick={(): void => {
                                    dispatch(removeOptionalOfferLocalWork(localWork));
                                  }}
                                >
                                  <Icon>close</Icon>
                                </Button>
                              </td>
                            )}
                            <td dangerouslySetInnerHTML={{ __html: localWork.name }} />
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    {offer.state === OfferState.Draft && (
                      <Button onClick={(): void => setInclusiveLocalWorksOpen(true)} primary>
                        Kies lokale werken
                      </Button>
                    )}
                    <Divider margin />
                    <h3>Status</h3>
                    <ButtonGroup>
                      <Button
                        onClick={() => handleOfferStateChange(OfferState.Draft)}
                        success={offerState === OfferState.Draft}
                      >
                        Draft
                      </Button>
                      <Button
                        onClick={() => handleOfferStateChange(OfferState.Sent)}
                        success={offerState === OfferState.Sent}
                      >
                        Verzonden
                      </Button>
                      <Button
                        onClick={async () => handleOfferStateChange(OfferState.Cancelled)}
                        success={offerState === OfferState.Cancelled}
                      >
                        Geannuleerd
                      </Button>
                    </ButtonGroup>
                    <Divider margin />
                    <ButtonGroup>
                      <Button secondary type="reset" onClick={handleCancelButton}>
                        Annuleren
                      </Button>
                      <Button
                        primary={offer.state !== OfferState.Sent || offer?.state !== offerState}
                        disabled={offerState === OfferState.Sent && offer?.state === OfferState.Sent}
                        type="submit"
                      >
                        Opslaan
                      </Button>
                      <Button type="button" onClick={handleGenerateOfferClick}>
                        Genereer offerte
                      </Button>
                    </ButtonGroup>
                  </FormGroupContainer>
                </FormGroupContainer>
              </form>
          )}
      </div>
      <LocalWorksModal handleClose={(): void => setInclusiveLocalWorksOpen(false)} isOpen={isInclusiveLocalWorksOpen} />
    </ContainerVertical>
  );
};

export default Edit;
