import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { requestLocalWork, submitLocalWork } from '../../actions';
import { useBetraconSelector } from '../../reducers';
import * as yup from 'yup';
import { useFormik } from 'formik';
import ContainerVertical from '@duik/container-vertical';
import TextField from '@duik/text-field';
import FormGroupContainer from '@duik/form-group-container';
import FormGroup from '@duik/form-group';
import ButtonGroup from '@duik/button-group';
import Divider from '@duik/divider';
import { Button } from '@duik/it';
import { useParams } from 'react-router-dom';
import { push } from 'connected-react-router';

import Loader from '../../components/Loader';

type LocalWorkParams = {
  id?: string;
};

const Edit: FC = () => {
  const dispatch = useDispatch();
  const state = useBetraconSelector((state) => state);
  const { id } = useParams<LocalWorkParams>();
  const {
    localWorks: { localWork, loading },
  } = state;

  useEffect((): void => {
    if (typeof id === 'undefined' || id === 'add') {
      dispatch(requestLocalWork());
    } else {
      dispatch(requestLocalWork(parseInt(id)));
    }
  }, [dispatch, id]);

  const validation = yup.object({
    name: yup.string().trim().required('Omschrijving is een verplicht veld'),
    price: yup.number().min(1, 'Prijs moet groter zijn dan 0').required('Prijs is een verplicht veld'),
  });

  type LocalWorkForm = yup.InferType<typeof validation>;

  const formik = useFormik<LocalWorkForm>({
    initialValues: {
      name: localWork?.name ?? '',
      price: localWork?.price ?? 0,
    },
    validationSchema: validation,
    onSubmit: (values) => {
      if (typeof localWork !== 'undefined') {
        const submitValue = localWork;
        Object.assign(submitValue, localWork, {
          name: values.name,
          price: values.price,
        });
        dispatch(submitLocalWork(submitValue));
      }
    },
    enableReinitialize: true,
  });

  const handleCancelButton = (): void => {
    dispatch(push('/local-works'));
  };

  return typeof localWork === 'undefined' ? null : (
    <ContainerVertical>
      <div className="container customer">
        <h1>Lokaal werk</h1>
        {typeof localWork === 'undefined' || loading ? (
          <Loader />
        ) : (
          <form onSubmit={formik.handleSubmit}>
            <FormGroupContainer>
              <FormGroupContainer>
                <h3>Lokaal werk</h3>
              </FormGroupContainer>
              <FormGroupContainer horizontal>
                <TextField
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  id="name"
                  name="name"
                  label="Omschrijving"
                  value={formik.values.name}
                  errorMessage={formik.touched.name && Boolean(formik.errors.name) && formik.errors.name}
                />
                <TextField
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  id="price"
                  name="price"
                  label="Prijs"
                  type="number"
                  value={formik.values.price}
                  errorMessage={formik.touched.price && Boolean(formik.errors.price) && formik.errors.price}
                />
              </FormGroupContainer>
            </FormGroupContainer>
            <Divider margin />

            <ButtonGroup>
              <Button secondary type="reset" onClick={handleCancelButton}>
                Annuleren
                  </Button>
              <Button primary type="submit">
                Opslaan
                  </Button>
            </ButtonGroup>
          </form>
        )}
      </div>
    </ContainerVertical>
  );
};

export default Edit;
