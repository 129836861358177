import React from 'react';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './sagas';
import rootReducer from './reducers';
import { initializeApplication } from './actions';
import { composeWithDevTools } from 'redux-devtools-extension';
import { ContainerVertical } from '@duik/container-vertical';
import { Route, Switch } from 'react-router';
import { ConnectedRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import NavigationBar from './components/NavigationBar';
import * as Customer from './containers/customer';
import * as Record from './containers/record';
import * as LocalWork from './containers/local-work';
import * as Offer from './containers/offer';
import * as Model from './containers/model';
import './App.scss';

const App: React.FC = () => {
  const sagaMiddleware = createSagaMiddleware();
  const history = createBrowserHistory();
  const store = createStore(
    rootReducer(history),
    composeWithDevTools(applyMiddleware(sagaMiddleware, routerMiddleware(history))),
  );
  sagaMiddleware.run(rootSaga);
  store.dispatch(initializeApplication());

  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <ContainerVertical>
          <NavigationBar />
          <Switch>
            <Route path="/records/:id/offer/:offerId" component={Offer.Edit} />
            <Route path="/local-works/:id" component={LocalWork.Edit} />
            <Route path="/local-works/add" component={LocalWork.Edit} />
            <Route path="/local-works" component={LocalWork.List} />
            <Route path="/records/:id" component={Record.Edit} />
            <Route path="/records/add" component={Record.Edit} />
            <Route path="/records" component={Record.List} />
            <Route path="/customers/:id" component={Customer.Edit} />
            <Route path="/customers/add" component={Customer.Edit} />
            <Route path="/customers" component={Customer.List} />
            <Route path="/" component={Model.Edit} />
          </Switch>
        </ContainerVertical>
      </ConnectedRouter>
    </Provider>
  );
};

export default App;
