import { FC, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

const Loader: FC = () => {
  const loader = useRef<HTMLDivElement | null>(null);
  return (
    <CSSTransition classNames="show" timeout={0}>
      <div ref={loader} className="loader" dangerouslySetInnerHTML={{ __html: 'Loading' }} />
    </CSSTransition>
  );
};

export default Loader;
