import { CustomerState } from '../../types/states';
import { Actions } from '../../types/actions';
import * as types from '../../types/constants';

const initialValues: CustomerState = {
  loading: false,
  customers: [],
  filteredCustomers: [],
  error: false,
  hasFoundCustomer: false,
};

const customers = (state = initialValues, action: Actions): CustomerState => {
  switch (action.type) {
    case types.REQUEST_CUSTOMER:
    case types.REQUEST_CUSTOMERS:
      return Object.assign({}, state, {
        loading: true,
        error: false,
      });
    case types.RECEIVE_CUSTOMERS:
      return Object.assign({}, state, {
        loading: false,
        customers: [...action.customers],
        error: false,
      });
    case types.RECEIVE_CUSTOMER:
      return Object.assign({}, state, {
        loading: false,
        customer: { ...action.customer },
        error: false,
      });
    case types.RECEIVE_CUSTOMER_SEARCH:
      if (action.customer === null) {
        return state;
      }
      return Object.assign({}, state, {
        loading: false,
        customer: { ...action.customer },
        error: false,
        hasFoundCustomer: true,
      });
    case types.RECEIVE_CUSTOMERS_SEARCH:
      return Object.assign({}, state, {
        filteredCustomers: action.customers.length === 1 ? [] : [...action.customers],
        hasFoundCustomer: action.customers.length === 1,
      });
    case types.CUSTOMER_ERROR:
    case types.CUSTOMERS_ERROR:
      return Object.assign({}, state, {
        loading: false,
        error: true,
      });
    case types.SUBMIT_CUSTOMER:
      return Object.assign({}, state, {
        loading: true,
        customer: action.customer,
        error: false,
      });
    default:
      return state;
  }
};

export default customers;
