import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { combineReducers } from 'redux';
import records from './records';
import offers from './offers';
import localWorks from './local-works';
import customers from './customers';
import { connectRouter, RouterState } from 'connected-react-router';
import { History } from 'history';
import { CustomerState, LocalWorkState, OfferState, RecordState } from '../types/states';

const rootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    records,
    offers,
    localWorks,
    customers,
  });

export type RootState = {
  router: RouterState;
  records: RecordState;
  offers: OfferState;
  localWorks: LocalWorkState;
  customers: CustomerState;
};

export const useBetraconSelector: TypedUseSelectorHook<RootState> = useSelector;

export default rootReducer;
