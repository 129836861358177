import React, { FC, useEffect, useRef } from 'react';
import ContainerVertical from '@duik/container-vertical';
import ScrollArea from '@duik/scroll-area';
import Button from '@duik/button';
import Table from 'react-bootstrap/Table';
import Icon from '@duik/icon';
import { Link } from 'react-router-dom';
import { useBetraconSelector } from '../../reducers';
import { useDispatch } from 'react-redux';
import { requestCustomers } from '../../actions';
import Loader from '../../components/Loader';


const List: FC = () => {
  const { customers, loading } = useBetraconSelector((state) => state.customers);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(requestCustomers());
  }, [dispatch]);

  const data = useRef<HTMLTableElement | null>(null);
  const duration = 2000;

  return (
    <ContainerVertical>
      <ScrollArea>
        <div className="container">
          <h1>Klanten</h1>
            {loading ? (
              <Loader />
            ) : (
                <Table ref={data} striped bordered size="sm">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Bedrijf</th>
                      <th>BTW-nummer</th>
                      <th>Stad</th>
                    </tr>
                  </thead>
                  <tbody>
                    {customers.map((customer, key) => (
                      <tr key={`customer-${key}`}>
                        <td>
                          <Button square transparent Component={Link} to={`/customers/${customer.id}`}>
                            <Icon>edit</Icon>
                          </Button>
                        </td>
                        <td>{customer.companyName}</td>
                        <td>{customer.vat}</td>
                        <td>{customer.city}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
            )}
        </div>
      </ScrollArea>
      <section className="actions">
        <Button primary Component={Link} to="/customers/add">
          Klant aanmaken
        </Button>
      </section>
    </ContainerVertical>
  );
};

export default List;
