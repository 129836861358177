import * as types from '../types/constants';
import * as models from '../types/models';
import { Actions } from '../types/actions';

export const requestCustomers = (): Actions => ({
  type: types.REQUEST_CUSTOMERS,
});

export const receiveCustomers = (customers: models.Customer[]): Actions => ({
  type: types.RECEIVE_CUSTOMERS,
  customers,
});

export const customersError = (): Actions => ({
  type: types.CUSTOMERS_ERROR,
});

export const requestCustomer = (id?: number): Actions => ({
  type: types.REQUEST_CUSTOMER,
  id,
});

export const receiveCustomer = (customer: models.Customer): Actions => ({
  type: types.RECEIVE_CUSTOMER,
  customer,
});

export const customerError = (): Actions => ({
  type: types.CUSTOMER_ERROR,
});

export const submitCustomer = (customer: models.Customer): Actions => ({
  type: types.SUBMIT_CUSTOMER,
  customer,
});

export const requestCustomerSearch = (customer: models.Customer): Actions => ({
  type: types.REQUEST_CUSTOMER_SEARCH,
  customer,
});

export const receiveCustomersSearch = (customers: models.Customer[]): Actions => ({
  type: types.RECEIVE_CUSTOMERS_SEARCH,
  customers,
});

export const receiveCustomerSearch = (customer: models.Customer | null): Actions => ({
  type: types.RECEIVE_CUSTOMER_SEARCH,
  customer,
});
