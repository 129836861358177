import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { requestRecord, submitRecord, submitRecordState } from '../../actions';
import { useBetraconSelector } from '../../reducers';
import * as yup from 'yup';
import { useFormik } from 'formik';
import ContainerVertical from '@duik/container-vertical';
import TextField from '@duik/text-field';
import FormGroupContainer from '@duik/form-group-container';
import FormGroup from '@duik/form-group';
import ButtonGroup from '@duik/button-group';
import Divider from '@duik/divider';
import { Button, TextArea } from '@duik/it';
import { Link, useParams } from 'react-router-dom';
import { push } from 'connected-react-router';
import Table from 'react-bootstrap/esm/Table';
import Icon from '@duik/icon';
import { Customer, ListItem, Offer, OfferState, RecordState, RecordStateHistory } from '../../types/models';
import { requestCustomerSearch } from '../../actions';
import * as lists from './lists';
import TypeaheadDropdown from '../../components/TypeaheadDropdown';

import Loader from '../../components/Loader';

type RecordParams = {
  id?: string;
};

const Edit: FC = () => {
  const dispatch = useDispatch();
  const state = useBetraconSelector((state) => state);
  const { id } = useParams<RecordParams>();
  const {
    records: { record, loading },
    customers: { filteredCustomers },
  } = state;
  const [typeTypeahead, setTypeTypeahead] = useState('');
  const [numberOfTonsTypeahead, setNumberOfTonsTypeahead] = useState('');
  const [axlesTypeahead, setAxlesTypeahead] = useState('');
  const [type2Typeahead, setType2Typeahead] = useState('');
  const [cabinTypeahead, setCabinTypeahead] = useState('');
  const [historyState, setHistoryState] = useState<RecordStateHistory>({
    id: 0,
    created: new Date(),
    state: record?.state?.state ?? RecordState.Pending,
    reason: '',
    recordId: record?.id ?? 0,
  });

  useEffect((): void => {
    if (typeof id === 'undefined' || id === 'add') {
      dispatch(requestRecord());
    } else {
      dispatch(requestRecord(parseInt(id)));
    }
  }, [dispatch, id]);

  useEffect((): void => {
    setHistoryState({
      id: 0,
      created: new Date(),
      state: record?.state?.state ?? RecordState.Pending,
      reason: record?.state?.reason ?? '',
      recordId: record?.id ?? 0,
    });
  }, [record]);

  const handleSelectCustomer = (customer: Customer): void => {
    dispatch(requestCustomerSearch(customer));
  };

  const isNewRecord = typeof id === 'undefined' || id === 'add';

  const customerValidation = yup.object({
    type: yup.string().trim().required('Type is een verplicht veld'),
    numberOfTons: yup.string().trim().required('Aantal ton is een verplicht veld'),
    axles: yup.string().trim().required('Axen is een verplicht veld'),
    type2: yup.string().trim().required('Type 2 is een verplicht veld'),
    cabin: yup.string().trim().required('Cabine is een verplicht veld'),
    companyName: yup.string().trim().required('Bedrijfsnaam is een verplicht veld'),
    vat: yup
      .string()
      .trim()
      .matches(/^(BE)?\s?0[0-9]{3}\.?[0-9]{3}\.?[0-9]{3}$/, 'BTW-nummer is niet in het correcte formaat')
      .required('BTW-nummer is een verplicht veld'),
    addressLine: yup.string().trim().required('Adres is een verplicht veld'),
    postalCode: yup.string().trim().required('Postcode is een verplicht veld'),
    city: yup.string().trim().required('Stad is een verplicht veld'),
  });

  type RecordForm = yup.InferType<typeof customerValidation>;

  const mapCustomers = useCallback(
    (values: RecordForm): Customer => ({
      companyName: values.companyName,
      vat: values.vat,
      addressLine: values.addressLine,
      postalCode: values.postalCode,
      city: values.city,
      id: 0,
      records: record?.customer.records ?? [],
    }),
    [record],
  );

  const formik = useFormik<RecordForm>({
    initialValues: {
      type: record?.type ?? '',
      numberOfTons: record?.numberOfTons ?? '',
      axles: record?.axles ?? '',
      type2: record?.type2 ?? '',
      cabin: record?.cabin ?? '',
      companyName: record?.customer?.companyName ?? '',
      vat: record?.customer?.vat ?? '',
      addressLine: record?.customer?.addressLine ?? '',
      postalCode: record?.customer?.postalCode ?? '',
      city: record?.customer?.city ?? '',
    },
    validationSchema: customerValidation,
    onSubmit: (values) => {
      if (typeof record !== 'undefined') {
        const submitValue = record;
        Object.assign(submitValue, record, {
          type: values?.type ?? '',
          numberOfTons: values?.numberOfTons ?? '',
          axles: values?.axles ?? '',
          type2: values?.type2 ?? '',
          cabin: values?.cabin ?? '',
          customer: mapCustomers(values),
        });
        dispatch(submitRecord(submitValue));
      }
    },
    enableReinitialize: true,
  });

  const filterTypes = (): ListItem[] =>
    lists.types.filter((f) => f.name.toLowerCase().indexOf(typeTypeahead.toLowerCase()) > -1);

  const filterNumberOfTons = (): ListItem[] =>
    lists.numberOfTons.filter((f) => f.name.toLowerCase().indexOf(numberOfTonsTypeahead.toLowerCase()) > -1);

  const filterAxles = (): ListItem[] =>
    lists.axles.filter((f) => f.name.toLowerCase().indexOf(axlesTypeahead.toLowerCase()) > -1);

  const filterType2s = (): ListItem[] =>
    lists.type2.filter((f) => f.name.toLowerCase().indexOf(type2Typeahead.toLowerCase()) > -1);

  const filterCabins = (): ListItem[] =>
    lists.cabin.filter((f) => f.name.toLowerCase().indexOf(cabinTypeahead.toLowerCase()) > -1);

  const handleCancelButton = (): void => {
    dispatch(push(isNewRecord ? '/records' : `/customers/${record?.customer.id}`));
  };

  useEffect((): void => {
    if (typeof id === 'undefined' || id === 'add') {
      dispatch(requestCustomerSearch(mapCustomers(formik.values)));
    }
  }, [dispatch, mapCustomers, id, formik.values]);

  const handleStatePress = (state: RecordState): void => {
    const newHistoryState = { ...historyState };
    newHistoryState.state = state;

    setHistoryState(newHistoryState);
  };

  const handleReasonChange = (reason: string): void => {
    const newHistoryState = { ...historyState };
    newHistoryState.reason = reason;

    setHistoryState(newHistoryState);
  };

  const handleStateSave = (): void => {
    if (historyState.state !== record?.state?.state) {
      const submitValue = { ...historyState };
      submitValue.reason = historyState.state === RecordState.Lost ? submitValue.reason : '';
      dispatch(submitRecordState(submitValue));
    }
  };

  const getOfferState = (offer: Offer): string => {
    switch (offer.state) {
      case OfferState.Draft:
        return 'Draft';
      case OfferState.Sent:
        return 'Verzonden';
      case OfferState.Cancelled:
        return 'Geannuleerd';
      default:
        return 'Draft';
    }
  };

  const convertDate = (date: Date): string => {
    return `${new Date(date).getDay().toString().padStart(2, '0')}/${(new Date(date).getMonth() + 1)
      .toString()
      .padStart(2, '0')}/${new Date(date).getFullYear()} ${new Date(date).getHours()}:${new Date(date)
      .getMinutes()
      .toString()
      .padStart(2, '0')}`;
  };

  return typeof record === 'undefined' ? null : (
    <ContainerVertical>
      <div className="container">
        <h1>Dossier</h1>
          {typeof record === 'undefined' || loading ? (
            <Loader />
          ) : (
              <form onSubmit={formik.handleSubmit}>
                <FormGroupContainer className="customer">
                  <FormGroupContainer>
                    <h3>Klant</h3>
                  </FormGroupContainer>
                  <FormGroupContainer horizontal>
                      <TextField
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        id="companyName"
                        name="companyName"
                        label="Bedrijfsnaam"
                        value={formik.values.companyName}
                        errorMessage={
                          formik.touched.companyName && Boolean(formik.errors.companyName) && formik.errors.companyName
                        }
                        disabled={!isNewRecord}
                      />
                      <TextField
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        id="vat"
                        name="vat"
                        label="BTW-nummer"
                        value={formik.values.vat}
                        errorMessage={formik.touched.vat && Boolean(formik.errors.vat) && formik.errors.vat}
                        disabled={!isNewRecord}
                      />
                  </FormGroupContainer>
                  <Divider margin />
                  <FormGroupContainer horizontal>
                      <TextField
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        id="addressLine"
                        name="addressLine"
                        label="Straat + huisnummer"
                        value={formik.values.addressLine}
                        errorMessage={
                          formik.touched.addressLine && Boolean(formik.errors.addressLine) && formik.errors.addressLine
                        }
                        disabled={!isNewRecord}
                      />
                  </FormGroupContainer>
                  <FormGroupContainer horizontal>
                      <TextField
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        id="postalCode"
                        name="postalCode"
                        label="Postcode"
                        value={formik.values.postalCode}
                        errorMessage={
                          formik.touched.postalCode && Boolean(formik.errors.postalCode) && formik.errors.postalCode
                        }
                        disabled={!isNewRecord}
                      />
                      <TextField
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        id="city"
                        name="city"
                        label="Gemeente"
                        value={formik.values.city}
                        errorMessage={formik.touched.city && Boolean(formik.errors.city) && formik.errors.city}
                        disabled={!isNewRecord}
                      />
                  </FormGroupContainer>
                  <FormGroupContainer horizontal></FormGroupContainer>
                </FormGroupContainer>
                {isNewRecord && filteredCustomers.length > 0 && (
                  <FormGroupContainer>
                    <h3>Mogelijke klanten</h3>
                    <Table striped bordered size="sm">
                      <thead>
                        <tr>
                          <th></th>
                          <th>Bedrijf</th>
                          <th>BTW-nummer</th>
                          <th>Stad</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredCustomers.map((item, key) => (
                          <tr key={`customer-${key}`}>
                            <td>
                              <Button square transparent onClick={(): void => handleSelectCustomer(item)}>
                                <Icon>check</Icon>
                              </Button>
                            </td>
                            <td>{item.companyName}</td>
                            <td>{item.vat}</td>
                            <td>{item.city}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </FormGroupContainer>
                )}
                <Divider margin />
                <FormGroupContainer>
                  <FormGroupContainer>
                    <h3>Configuratie</h3>
                  </FormGroupContainer>
                  <FormGroupContainer horizontal>
                    <FormGroup>
                      <label htmlFor="type">Type</label>
                      <TypeaheadDropdown
                        buttonText={formik.values.type.trim().length === 0 ? `Type` : formik.values.type}
                        handleInputChange={(value) => setTypeTypeahead(value)}
                        list={filterTypes()}
                        listPlaceholder="Kies uw type"
                        handleOnSelect={async (value) => formik.setFieldValue('type', value.name)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <label htmlFor="numberOfTons">Tonnage</label>
                      <TypeaheadDropdown
                        buttonText={
                          formik.values.numberOfTons.trim().length === 0 ? `Tonnage` : formik.values.numberOfTons
                        }
                        handleInputChange={(value) => setNumberOfTonsTypeahead(value)}
                        list={filterNumberOfTons()}
                        listPlaceholder="Kies uw tonnage"
                        handleOnSelect={async (value) => formik.setFieldValue('numberOfTons', value.name)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <label htmlFor="axles">Assen</label>
                      <TypeaheadDropdown
                        buttonText={formik.values.axles.trim().length === 0 ? `Assen` : formik.values.axles}
                        handleInputChange={(value) => setAxlesTypeahead(value)}
                        list={filterAxles()}
                        listPlaceholder="Kies uw assen"
                        handleOnSelect={async (value) => formik.setFieldValue('axles', value.name)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <label htmlFor="type2">Type2</label>
                      <TypeaheadDropdown
                        buttonText={formik.values.type2.trim().length === 0 ? `Type 2` : formik.values.type2}
                        handleInputChange={(value) => setType2Typeahead(value)}
                        list={filterType2s()}
                        listPlaceholder="Kies uw type 2"
                        handleOnSelect={async (value) => formik.setFieldValue('type2', value.name)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <label htmlFor="cabin">Cabine</label>
                      <TypeaheadDropdown
                        buttonText={formik.values.cabin.trim().length === 0 ? `Cabine` : formik.values.cabin}
                        handleInputChange={(value) => setCabinTypeahead(value)}
                        list={filterCabins()}
                        listPlaceholder="Kies uw cabine"
                        handleOnSelect={async (value) => formik.setFieldValue('cabin', value.name)}
                      />
                    </FormGroup>
                  </FormGroupContainer>
                </FormGroupContainer>
                <Divider margin />
                {typeof record.state !== 'undefined' && record.state !== null && (
                  <>
                    <FormGroupContainer>
                      <h3>Status</h3>
                      <ButtonGroup>
                        <Button
                          onClick={(): void => handleStatePress(RecordState.Pending)}
                          success={historyState.state === RecordState.Pending}
                        >
                          Pending
                        </Button>
                        <Button
                          onClick={(): void => handleStatePress(RecordState.Won)}
                          success={historyState.state === RecordState.Won}
                        >
                          Won
                        </Button>
                        <Button
                          onClick={(): void => handleStatePress(RecordState.Lost)}
                          success={historyState.state === RecordState.Lost}
                        >
                          Lost
                        </Button>
                      </ButtonGroup>
                      {historyState.state === RecordState.Lost && (
                        <FormGroupContainer>
                          <TextArea
                            label="Reden"
                            value={historyState.reason}
                            placeholder="Geef hier een reden waarom het dossier niet gehaald werd"
                            onChange={(e): void => handleReasonChange(e.target.value)}
                          />
                        </FormGroupContainer>
                      )}
                      <Button
                        onClick={handleStateSave}
                        primary={historyState.state !== record?.state?.state}
                        disabled={historyState.state === record?.state?.state}
                      >
                        Status aanpassen
                      </Button>
                    </FormGroupContainer>
                    <Divider margin />
                  </>
                )}
                {typeof id !== 'undefined' &&
                  id !== 'add' &&
                  typeof record.offers !== 'undefined' &&
                  record.offers !== null && (
                    <>
                      <FormGroupContainer>
                        <h3>Offertes</h3>
                      </FormGroupContainer>
                      <FormGroupContainer>
                        <Table striped bordered size="sm">
                          <thead>
                            <tr>
                              <th></th>
                              <th>Versie</th>
                              <th>MAN code</th>
                              <th>Status</th>
                              <th>Laatst aangepast</th>
                            </tr>
                          </thead>
                          <tbody>
                            {record.offers.map((offer, key) => (
                              <tr key={`offer-${key}`}>
                                <td>
                                  <Button
                                    square
                                    transparent
                                    Component={Link}
                                    to={`/records/${record.id}/offer/${offer.id}`}
                                  >
                                    <Icon>edit</Icon>
                                  </Button>
                                </td>
                                <td>{`${offer.version}`}</td>
                                <td>{`${offer.manCode}`}</td>
                                <td>{`${getOfferState(offer)}`}</td>
                                <td>{`${convertDate(
                                  typeof offer.lastModified === 'undefined' ? new Date() : offer.lastModified,
                                )}`}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </FormGroupContainer>
                      <FormGroupContainer>
                        <Button sm Component={Link} to={`/records/${id}/offer/add`}>
                          Offerte toevoegen
                        </Button>
                      </FormGroupContainer>
                      <Divider margin />
                    </>
                  )}
                <ButtonGroup>
                  <Button secondary type="reset" onClick={handleCancelButton}>
                    Annuleren
                  </Button>
                  <Button primary type="submit">
                    Opslaan
                  </Button>
                </ButtonGroup>
              </form>
          )}
      </div>
    </ContainerVertical>
  );
};

export default Edit;
