import { LocalWorkState } from '../../types/states';
import { Actions } from '../../types/actions';
import * as types from '../../types/constants';

const initialValues: LocalWorkState = {
  loading: false,
  localWorks: [],
  error: false,
};

const localWorks = (state = initialValues, action: Actions): LocalWorkState => {
  switch (action.type) {
    case types.REQUEST_LOCAL_WORK:
    case types.REQUEST_LOCAL_WORKS:
      return Object.assign({}, state, {
        loading: true,
        error: false,
      });
    case types.RECEIVE_LOCAL_WORKS:
      return Object.assign({}, state, {
        loading: false,
        localWorks: action.localWorks,
        error: false,
      });
    case types.RECEIVE_LOCAL_WORK:
      return Object.assign({}, state, {
        loading: false,
        localWork: action.localWork,
        error: false,
      });
    case types.LOCAL_WORK_ERROR:
    case types.LOCAL_WORKS_ERROR:
      return Object.assign({}, state, {
        loading: false,
        error: true,
      });
    case types.SUBMIT_LOCAL_WORK:
      return Object.assign({}, state, {
        loading: true,
        localWork: action.localWork,
        error: false,
      });
    default:
      return state;
  }
};

export default localWorks;
