import { all } from 'redux-saga/effects';
import recordsSaga from './records';
import offersSaga from './offers';
import customersSaga from './customers';
import localWorksSaga from './local-works';
import modelsSaga from './models';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export default function* rootSaga() {
  yield all([recordsSaga(), offersSaga(), localWorksSaga(), customersSaga(), modelsSaga()]);
}
