import * as types from '../types/constants';
import * as models from '../types/models';
import { Actions } from '../types/actions';

export const requestPriceCalculation = (offer: models.Offer): Actions => ({
  type: types.REQUEST_PRICE_CALCULATION,
  offer,
});

export const receivePriceCalculation = (offer: models.Offer): Actions => ({
  type: types.RECEIVE_PRICE_CALCULATION,
  offer,
});

export const priceCalculationError = (): Actions => ({
  type: types.PRICE_CALCULATION_ERROR,
});
