import React, { FC, useState } from 'react';
import Dropdown, { DropdownItem } from '@duik/dropdown';
import TextField from '@duik/text-field';
import * as models from '../types/models';
import Icon from '@duik/icon';

type Props = {
  list: models.ListItem[];
  buttonText: string;
  listPlaceholder: string;
  handleInputChange: (value: string) => void;
  handleOnSelect: (item: models.ListItem) => void;
};

const TypeaheadDropdown: FC<Props> = (props) => {
  const { list, buttonText, listPlaceholder, handleInputChange, handleOnSelect } = props;
  const [typeaheadValue, setTypeaheadValue] = useState('');

  const handleTypeaheadChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setTypeaheadValue(e.target.value);
    handleInputChange(e.target.value);
  };

  const getFilteredList = (): models.ListItem[] => {
    return list.filter((f) => f.name.toLowerCase().indexOf(typeaheadValue.toLowerCase()) > -1);
  };

  return (
    <Dropdown buttonText={buttonText}>
      {({ handleClose }) => (
        <>
          <DropdownItem>
            <div style={{ width: '100%' }}>
              <TextField
                placeholder={listPlaceholder}
                onChange={handleTypeaheadChange}
                value={typeaheadValue}
                rightEl={<Icon>search_left</Icon>}
              />
            </div>
          </DropdownItem>
          {getFilteredList().map((listItem, key) => (
            <DropdownItem
              key={`listItem-${key}`}
              onClick={(): void => {
                handleOnSelect(listItem);
                handleClose();
              }}
            >
              <span dangerouslySetInnerHTML={{ __html: listItem.name }} />
            </DropdownItem>
          ))}
        </>
      )}
    </Dropdown>
  );
};

export default TypeaheadDropdown;
