import * as types from '../types/constants';
import { Actions } from '../types/actions';

export * from './records';
export * from './record-state-history';
export * from './customers';
export * from './offers';
export * from './price-calculations';
export * from './local-works';
export * from './models';

export const initializeApplication = (): Actions => ({
  type: types.INITIALIZE_APPLICATION,
});
