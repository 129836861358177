import { takeLatest, all, call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { LocalWork } from '../types/models';
import * as api from '../api';
import { REQUEST_LOCAL_WORK, REQUEST_LOCAL_WORKS, SUBMIT_LOCAL_WORK } from '../types/constants';
import { localWorksError, receiveLocalWorks, receiveLocalWork, localWorkError } from '../actions';
import { RequestLocalWork, SubmitLocalWork } from '../types/actions';
import { push } from 'connected-react-router';

function* requestLocalWork(action: RequestLocalWork): SagaIterator {
  let localWork: LocalWork | undefined = undefined;
  if (typeof action.id === 'undefined') {
    localWork = {
      id: 0,
      name: '',
      price: 0,
    };
  } else {
    try {
      localWork = yield call(api.getLocalWork, action.id);
    } catch {
      yield put(localWorkError());
    }
  }
  if (typeof localWork !== 'undefined') {
    yield put(receiveLocalWork(localWork));
  }
}

function* getAllLocalWorks(): SagaIterator {
  try {
    const localWorks: LocalWork[] = yield call(api.getAllLocalWorks);
    yield put(receiveLocalWorks(localWorks));
  } catch (error) {
    console.error(error);
    yield put(localWorksError());
  }
}

function* saveLocalWork(action: SubmitLocalWork): SagaIterator {
  const redirect = action.localWork.id < 1;
  if (redirect) {
    const localWork: LocalWork = yield call(api.saveLocalWork, action.localWork);
    yield put(receiveLocalWork(localWork));
    yield put(push(`/local-works/${localWork.id}`));
  } else {
    const localWork: LocalWork = yield call(api.updateLocalWork, action.localWork);
    yield put(receiveLocalWork(localWork));
  }
}

function* watchRequestLocalWorks(): SagaIterator {
  yield takeLatest(REQUEST_LOCAL_WORKS, getAllLocalWorks);
}

function* watchRequestLocalWork(): SagaIterator {
  yield takeLatest(REQUEST_LOCAL_WORK, requestLocalWork);
}

function* watchSubmitLocalWork(): SagaIterator {
  yield takeLatest(SUBMIT_LOCAL_WORK, saveLocalWork);
}

export default function* localWorksSaga(): unknown {
  yield all([watchRequestLocalWork(), watchRequestLocalWorks(), watchSubmitLocalWork()]);
}
