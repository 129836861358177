import { FC, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { requestCustomer, submitCustomer } from '../../actions';
import { useBetraconSelector } from '../../reducers';
import * as yup from 'yup';
import { useFormik } from 'formik';
import ContainerVertical from '@duik/container-vertical';
import TextField from '@duik/text-field';
import FormGroupContainer from '@duik/form-group-container';
import FormGroup from '@duik/form-group';
import ButtonGroup from '@duik/button-group';
import Divider from '@duik/divider';
import { Button, ContainerHorizontal } from '@duik/it';
import { Link, useParams } from 'react-router-dom';
import { push } from 'connected-react-router';
import Table from 'react-bootstrap/esm/Table';
import Icon from '@duik/icon';
import { Customer, RecordListItem, RecordState } from '../../types/models';
import { requestCustomerSearch } from '../../actions';

import Loader from '../../components/Loader';

type Params = {
  id?: string;
};

const Edit: FC = () => {
  const dispatch = useDispatch();
  const state = useBetraconSelector((state) => state);
  const { id } = useParams<Params>();
  const {
    customers: { customer, filteredCustomers, loading },
  } = state;

  useEffect((): void => {
    if (typeof id === 'undefined' || id === 'add') {
      dispatch(requestCustomer());
    } else {
      dispatch(requestCustomer(parseInt(id)));
    }
  }, [dispatch, id]);

  const customerValidation = yup.object({
    companyName: yup.string().trim().required('Bedrijfsnaam is een verplicht veld'),
    vat: yup
      .string()
      .trim()
      .matches(/^(BE)?\s?0[0-9]{3}\.?[0-9]{3}\.?[0-9]{3}$/, 'BTW-nummer is niet in het correcte formaat')
      .required('BTW-nummer is een verplicht veld'),
    addressLine: yup.string().trim().required('Adres is een verplicht veld'),
    postalCode: yup.string().trim().required('Postcode is een verplicht veld'),
    city: yup.string().trim().required('Stad is een verplicht veld'),
  });

  type CustomerForm = yup.InferType<typeof customerValidation>;

  const mapCustomer = useCallback(
    (values: CustomerForm): Customer => {
      return Object.assign({}, customer, {
        companyName: values.companyName,
        vat: values.vat,
        addressLine: values.addressLine,
        postalCode: values.postalCode,
        city: values.city,
      });
    },
    [customer],
  );

  const formik = useFormik<CustomerForm>({
    initialValues: {
      companyName: customer?.companyName ?? '',
      vat: customer?.vat ?? '',
      addressLine: customer?.addressLine ?? '',
      postalCode: customer?.postalCode ?? '',
      city: customer?.city ?? '',
    },
    validationSchema: customerValidation,
    onSubmit: (values) => {
      if (typeof customer !== 'undefined') {
        dispatch(submitCustomer(mapCustomer(values)));
      }
    },
    enableReinitialize: true,
  });

  const handleCancelButton = (): void => {
    dispatch(requestCustomer());
    dispatch(push('/customers'));
  };

  useEffect((): void => {
    if (typeof id === 'undefined' || id === 'add') {
      dispatch(requestCustomerSearch(mapCustomer(formik.values)));
    }
  }, [dispatch, mapCustomer, id, formik.values]);

  const handleSelectCustomer = (customer: Customer): void => {
    dispatch(requestCustomerSearch(customer));
  };

  const isNewCustomer = typeof id === 'undefined' || id === 'add';

  const getFilteredRecords = (state: RecordState): RecordListItem[] => typeof customer === 'undefined' || typeof customer.records === 'undefined' ? [] : customer?.records.filter(record => (record.state?.state ?? RecordState.Pending) === state);

  return (
    <ContainerVertical>
      <div className="container customer">
        <h1>Klant</h1>
        {typeof customer === 'undefined' || loading ? (
          <Loader />
        ) : (
          <form onSubmit={formik.handleSubmit}>
            <FormGroupContainer>
              <FormGroupContainer>
                <h3>Gegevens</h3>
              </FormGroupContainer>
              <FormGroupContainer horizontal>
                <TextField
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  id="companyName"
                  name="companyName"
                  label="Bedrijfsnaam"
                  value={formik.values.companyName}
                  errorMessage={
                    formik.touched.companyName && Boolean(formik.errors.companyName) && formik.errors.companyName
                  }
                />
                <TextField
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  id="vat"
                  name="vat"
                  label="BTW-nummer"
                  value={formik.values.vat}
                  errorMessage={formik.touched.vat && Boolean(formik.errors.vat) && formik.errors.vat}
                />
              </FormGroupContainer>
              <Divider margin />
              <FormGroupContainer horizontal>
                <TextField
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  id="addressLine"
                  name="addressLine"
                  label="Straat + huisnummer"
                  value={formik.values.addressLine}
                  errorMessage={
                    formik.touched.addressLine && Boolean(formik.errors.addressLine) && formik.errors.addressLine
                  }
                />
              </FormGroupContainer>
              <FormGroupContainer horizontal>
                <TextField
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  id="postalCode"
                  name="postalCode"
                  label="Postcode"
                  value={formik.values.postalCode}
                  errorMessage={
                    formik.touched.postalCode && Boolean(formik.errors.postalCode) && formik.errors.postalCode
                  }
                />
                <TextField
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  id="city"
                  name="city"
                  label="Gemeente"
                  value={formik.values.city}
                  errorMessage={formik.touched.city && Boolean(formik.errors.city) && formik.errors.city}
                />
              </FormGroupContainer>
              <FormGroupContainer horizontal></FormGroupContainer>
            </FormGroupContainer>
            {isNewCustomer && filteredCustomers.length > 0 && (
              <FormGroupContainer>
                <h3>Mogelijke klanten</h3>
                <Table striped bordered size="sm">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Bedrijf</th>
                      <th>BTW-nummer</th>
                      <th>Stad</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredCustomers.map((item, key) => (
                      <tr key={`customer-${key}`}>
                        <td>
                          <Button square transparent onClick={(): void => handleSelectCustomer(item)}>
                            <Icon>check</Icon>
                          </Button>
                        </td>
                        <td>{item.companyName}</td>
                        <td>{item.vat}</td>
                        <td>{item.city}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </FormGroupContainer>
            )}
            <Divider margin />
            {typeof id !== 'undefined' && id !== 'add' && (
              <>
                <FormGroupContainer>
                  <h3>Dossiers</h3>
                </FormGroupContainer>
                {typeof customer.records !== 'undefined' && customer.records !== null && (
                  <FormGroupContainer>
                    <ContainerHorizontal>
                      <ContainerVertical className="container__records">
                        <h2>Pending</h2>
                        {getFilteredRecords(RecordState.Pending).length == 0 ? <span>Er zijn geen pending dossiers</span> : (<Table striped bordered size="sm">
                          <thead>
                            <tr>
                              <th></th>
                              <th>Type</th>
                              <th>Tonnage</th>
                              <th>Assen</th>
                              <th>Type2</th>
                              <th>Cabine</th>
                            </tr>
                          </thead>
                          <tbody>
                            {getFilteredRecords(RecordState.Pending).map((record, key) => (
                              <tr key={`record-${key}`}>
                                <td>
                                  <Button square transparent Component={Link} to={`/records/${record.id}`}>
                                    <Icon>edit</Icon>
                                  </Button>
                                </td>
                                <td>{record.type}</td>
                                <td>{record.numberOfTons}</td>
                                <td>{record.axles}</td>
                                <td>{record.type2}</td>
                                <td>{record.cabin}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>)}
                      </ContainerVertical>
                      <ContainerVertical className="container__records">
                        <h2>Won</h2>
                        {getFilteredRecords(RecordState.Won).length == 0 ? <span>Er zijn geen gewonnen dossiers</span> : (<Table striped bordered size="sm">
                          <thead>
                            <tr>
                              <th></th>
                              <th>Type</th>
                              <th>Tonnage</th>
                              <th>Assen</th>
                              <th>Type2</th>
                              <th>Cabine</th>
                            </tr>
                          </thead>
                          <tbody>
                            {getFilteredRecords(RecordState.Won).map((record, key) => (
                              <tr key={`record-${key}`}>
                                <td>
                                  <Button square transparent Component={Link} to={`/records/${record.id}`}>
                                    <Icon>edit</Icon>
                                  </Button>
                                </td>
                                <td>{record.type}</td>
                                <td>{record.numberOfTons}</td>
                                <td>{record.axles}</td>
                                <td>{record.type2}</td>
                                <td>{record.cabin}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>)}
                      </ContainerVertical>
                      <ContainerVertical className="container__records">
                        <h2>Lost</h2>
                        {getFilteredRecords(RecordState.Lost).length == 0 ? <span>Er zijn geen verloren dossiers</span> : (<Table striped bordered size="sm">
                          <thead>
                            <tr>
                              <th></th>
                              <th>Type</th>
                              <th>Tonnage</th>
                              <th>Assen</th>
                              <th>Type2</th>
                              <th>Cabine</th>
                            </tr>
                          </thead>
                          <tbody>
                            {getFilteredRecords(RecordState.Lost).map((record, key) => (
                              <tr key={`record-${key}`}>
                                <td>
                                  <Button square transparent Component={Link} to={`/records/${record.id}`}>
                                    <Icon>edit</Icon>
                                  </Button>
                                </td>
                                <td>{record.type}</td>
                                <td>{record.numberOfTons}</td>
                                <td>{record.axles}</td>
                                <td>{record.type2}</td>
                                <td>{record.cabin}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>)}
                      </ContainerVertical>
                    </ContainerHorizontal>
                  </FormGroupContainer>
                )}

                <FormGroupContainer>
                  <Button sm Component={Link} to={`/records/add`}>
                    Dossier aanmaken
                      </Button>
                </FormGroupContainer>
                <Divider margin />
              </>
            )}
            <ButtonGroup>
              <Button secondary type="reset" onClick={handleCancelButton}>
                Annuleren
                  </Button>
              <Button primary type="submit">
                Opslaan
                  </Button>
            </ButtonGroup>
          </form>
        )}
      </div>
    </ContainerVertical>
  );
};

export default Edit;
