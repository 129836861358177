import { RecordState } from '../../types/states';
import { Actions } from '../../types/actions';
import * as types from '../../types/constants';

const initialValues: RecordState = {
  loading: false,
  records: [],
  error: false,
};

const records = (state = initialValues, action: Actions): RecordState => {
  switch (action.type) {
    case types.REQUEST_RECORD:
    case types.REQUEST_RECORDS:
      return Object.assign({}, state, {
        loading: true,
        error: false,
      });
    case types.RECEIVE_RECORDS:
      return Object.assign({}, state, {
        loading: false,
        records: action.records,
        error: false,
      });
    case types.RECEIVE_RECORD:
      return Object.assign({}, state, {
        loading: false,
        record: action.record,
        error: false,
      });
    case types.RECEIVE_RECORD_OFFERS:
      const receiveOfferState = { ...state.record };
      receiveOfferState.offers = action.offers === null ? [] : [...action.offers];
      return Object.assign({}, state, {
        loading: false,
        record: receiveOfferState,
        error: false,
      });
    case types.RECEIVE_RECORD_STATE:
      const receiveRecordState = { ...state.record };
      receiveRecordState.state = { ...action.state };
      return Object.assign({}, state, {
        loading: false,
        record: receiveRecordState,
        error: false,
      });
    case types.RECEIVE_PRICE_CALCULATION:
      if (JSON.stringify(action.offer.record) === JSON.stringify(state.record)) {
        return state;
      }
      return Object.assign({}, state, {
        loading: false,
        record: { ...action.offer.record },
        error: false,
      });
    case types.RECEIVE_CUSTOMER_SEARCH:
      if (action.customer === null || typeof state.record === 'undefined') {
        return state;
      }
      const newRecord = { ...state.record };
      newRecord.customer = { ...action.customer };
      return Object.assign({}, state, {
        loading: false,
        record: newRecord,
        error: false,
      });
    case types.RECORD_ERROR:
    case types.RECORDS_ERROR:
      return Object.assign({}, state, {
        loading: false,
        error: true,
      });
    case types.SUBMIT_RECORD:
      return Object.assign({}, state, {
        loading: true,
        record: action.record,
        error: false,
      });
    default:
      return state;
  }
};

export default records;
