import { SubmitModel } from '../types/actions';
import { takeLatest, all, put, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import * as api from '../api';
import { Model } from '../types/models';
import { SUBMIT_MODEL } from '../types/constants';
import { push } from 'connected-react-router';

function* saveModel(action: SubmitModel): SagaIterator {
    const model: Model = yield call(api.saveModel, action.model, action.file);
    yield put(push(`records/${model.record.id}/offer/${model.offer.id}`));
}


function* watchSubmit(): SagaIterator {
  yield takeLatest(SUBMIT_MODEL, saveModel);
}


export default function* modelsSaga(): unknown {
  yield all([watchSubmit()]);
}
