import React, { FC, useRef } from 'react';

type UploadSectionProps = {
  handleFileChange: (files: FileList | null) => void;
  accept: string;
  uploadText: string;
  modifier?: string;
  containsFile: boolean;
};

const UploadSection: FC<UploadSectionProps> = (props) => {
  const { handleFileChange, accept, uploadText, modifier, containsFile } = props;

  const fileUpload = useRef<HTMLInputElement>(null);

  const handleUploadSectionClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    if (fileUpload !== null && fileUpload.current !== null) {
      fileUpload.current.click();
    }
  };

  const handleDrag = (event: React.DragEvent<HTMLDivElement>): void => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>): void => {
    event.preventDefault();
    let type = `.${event.dataTransfer.files[0].type.split('/')[1]}`;
    if (type === '.vnd.ms-excel.sheet.macroEnabled.12') {
      type = '.xlsm';
    } else if (type === '.vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      type = '.xlsx';
    }

    if (accept.indexOf(type) > -1) {
      handleFileChange(event.dataTransfer.files);
    }
  };

  return (
    <button
      type="button"
      className={
        'upload-section' +
        (containsFile ? ` upload-section--uploaded` : '') +
        (typeof modifier !== 'undefined' && modifier?.trim()?.length > 0 && ` upload-section--${modifier}`)
      }
      onClick={handleUploadSectionClick}
    >
      <div className="upload-section__wrapper" onDragEnter={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}>
        <div className="upload-section__content">
          <label className="upload-section__upload" />
          <span className="upload-section__upload-zone" dangerouslySetInnerHTML={{ __html: uploadText }} />
          <input
            ref={fileUpload}
            type="file"
            className="upload-section__file"
            accept={accept}
            onChange={(e): void => handleFileChange(e.target.files)}
          />
        </div>
      </div>
    </button>
  );
};

export default UploadSection;
