import { Model, Models, Offer } from '../types/models';
import download from 'downloadjs';

const host = `https://offerte-man.betracon.be`;
// const host = '';

const callApi = (action: RequestInfo, method: string, body?: Models, signal?: AbortSignal): Promise<Models> => {
  const request: RequestInit = {
    method: method,
    headers: {
      'Content-Type': 'application/json',
    },
    signal,
  };
  if (typeof body !== 'undefined') {
    request.body = JSON.stringify(body);
  }

  return new Promise((resolve, reject) => {
    if (signal) {
      signal.addEventListener('abort', () => {
        reject(new DOMException('Aborted', 'AbortError'));
      });
    }
    fetch(`${host}${action}`, request)
      .then((response) => {
        if (response.status === 401) {
          reject(new Error('Unauthorized'));
        }
        response
          .json()
          .then((result: Models) => {
            resolve(result);
          })
          .catch((error) => console.log(error));
      })
      .catch((error) => {
        if (error.name === 'AbortError') {
          console.log('cancelled');
        }
      });
  });
};

export const handleOfferApi = (action: RequestInfo, method: string, body: Offer, file: File): Promise<Models> => {
  const request: RequestInit = {
    method: method,
  };
  if (typeof body?.upload !== 'undefined') {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('model', JSON.stringify(body));
    request.body = formData;
  }

  return new Promise((resolve, reject) => {
    fetch(`${host}${action}`, request).then((response) => {
      if (response.status === 401) {
        reject(new Error('Unauthorized'));
      }
      response.json().then((result: Models) => {
        resolve(result);
      });
    });
  });
};

export const handleRecordApi = (action: RequestInfo, method: string, body: Model, file: File): Promise<Models> => {
  const request: RequestInit = {
    method: method,
  };
  const formData = new FormData();
  formData.append('file', file);
  formData.append('model', JSON.stringify(body));
  request.body = formData;

  return new Promise((resolve, reject) => {
    fetch(`${host}${action}`, request).then((response) => {
      if (response.status === 401) {
        reject(new Error('Unauthorized'));
      }
      response.json().then((result: Models) => {
        resolve(result);
      });
    });
  });
};

export const callGenerateApi = (action: RequestInfo, method: string, body?: Models): Promise<void> => {
  const request: RequestInit = {
    method: method,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  if (typeof body !== 'undefined') {
    request.body = JSON.stringify(body);
  }

  return new Promise((resolve, reject) => {
    fetch(`${host}${action}`, request).then((response) => {
      if (response.status === 401) {
        reject(new Error('Unauthorized'));
      }
      response.blob().then((blob) => download(blob, 'offer.pdf'));
      resolve();
    });
  });
};

export default callApi;
