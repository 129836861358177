import { FC, useCallback, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  removeIncludedOfferLocalWork,
  requestCustomer,
  requestLocalWorks,
  requestOffer,
  requestPriceCalculation,
  requestRecord,
  submitModel,
} from '../../actions';
import { useBetraconSelector } from '../../reducers';
import * as yup from 'yup';
import { useFormik } from 'formik';
import ContainerVertical from '@duik/container-vertical';
import TextField from '@duik/text-field';
import FormGroupContainer from '@duik/form-group-container';
import FormGroup from '@duik/form-group';
import ButtonGroup from '@duik/button-group';
import Divider from '@duik/divider';
import { Button } from '@duik/it';
import Table from 'react-bootstrap/esm/Table';
import Icon from '@duik/icon';
import { Customer, ListItem, LocalWork, Offer, OfferState, Record } from '../../types/models';
import { requestCustomerSearch } from '../../actions';

import Loader from '../../components/Loader';
import TypeaheadDropdown from '../../components/TypeaheadDropdown';
import * as lists from '../record/lists';
import UploadSection from '../../components/UploadSection';
import LocalWorksModal from '../offer/LocalWorksModal';
import _ from 'lodash';

const Edit: FC = () => {
  const dispatch = useDispatch();
  const state = useBetraconSelector((state) => state);
  const {
    records: { record, loading },
    customers: { filteredCustomers, hasFoundCustomer, customer },
    offers: { offer },
  } = state;
  const [typeTypeahead, setTypeTypeahead] = useState('');
  const [numberOfTonsTypeahead, setNumberOfTonsTypeahead] = useState('');
  const [axlesTypeahead, setAxlesTypeahead] = useState('');
  const [type2Typeahead, setType2Typeahead] = useState('');
  const [cabinTypeahead, setCabinTypeahead] = useState('');
  const [file, setFile] = useState<File>();
  const [isInclusiveLocalWorksOpen, setInclusiveLocalWorksOpen] = useState<boolean>(false);
  const [version, setVersion] = useState('');

  useEffect((): void => {
    dispatch(requestCustomer());
    dispatch(requestRecord());
    dispatch(requestOffer());
  }, [dispatch]);

  const validation = yup.object({
    type: yup.string().trim().required('Type is een verplicht veld'),
    numberOfTons: yup.string().trim().required('Aantal ton is een verplicht veld'),
    axles: yup.string().trim().required('Axen is een verplicht veld'),
    type2: yup.string().trim().required('Type 2 is een verplicht veld'),
    cabin: yup.string().trim().required('Cabine is een verplicht veld'),
    companyName: yup.string().trim().required('Bedrijfsnaam is een verplicht veld'),
    vat: yup
      .string()
      .trim()
      .matches(/^(BE)?\s?0[0-9]{3}\.?[0-9]{3}\.?[0-9]{3}$/, 'BTW-nummer is niet in het correcte formaat')
      .required('BTW-nummer is een verplicht veld'),
    addressLine: yup.string().trim().required('Adres is een verplicht veld'),
    postalCode: yup.string().trim().required('Postcode is een verplicht veld'),
    city: yup.string().trim().required('Stad is een verplicht veld'),
    version: yup.string().trim().required('Versie is een verplicht veld'),
    grossAmountCatalogPrice: yup
      .number()
      .min(0, 'Bruto catalogusprijs moet groter zijn dan 0')
      .required('Bruto catalogusprijs is verplicht'),
    optionsCatalogPrice: yup.number().min(0, 'Somopties moet groter zijn dan 0').required('Somopties is verplicht'),
    autoApproveReductionPercentage: yup.number().required('Auto approve korting - percentage is een verplicht veld'),
    autoApproveReduction: yup.number().required('Auto approve is een verplicht veld'),
    extraReductionPercentage: yup.number().required('Extra korting - percentage is een verplicht veld'),
    extraReduction: yup.number().required('Extra korting is een verplicht veld'),
    transportAndRecycle: yup
      .number()
      .min(0, 'Transport en recyclage kosten moet groter zijn dan 0')
      .required('Transport en recyclage kosten is verplicht'),
    salesPrice: yup.number().min(0, 'Verkoopprijs moet groter zijn dan 0').required('Verkoopprijs is verplicht'),
  });

  type Form = yup.InferType<typeof validation>;

  const mapCustomer = useCallback(
    (values: Form): Customer => ({
      companyName: values.companyName,
      vat: values.vat,
      addressLine: values.addressLine,
      postalCode: values.postalCode,
      city: values.city,
      id: 0,
      records: record?.customer?.records ?? [],
    }),
    [record],
  );

  const mapRecord = useCallback((values: Form): Record => {
    return Object.assign({}, record, {
      type: values?.type ?? '',
      numberOfTons: values?.numberOfTons ?? '',
      axles: values?.axles ?? '',
      type2: values?.type2 ?? '',
      cabin: values?.cabin ?? '',
    })
  }, [record])

  const mapOfferValues = useCallback(
    (values: Form): Offer => {
      const pdfPrice = { ...offer?.pdfPrice };
      pdfPrice.grossAmountCatalogPrice = values?.grossAmountCatalogPrice ?? 0;
      pdfPrice.optionsCatalogPrice = values?.optionsCatalogPrice ?? 0;

      const nettoPrice = { ...offer?.nettoPrice };
      nettoPrice.autoApproveReductionPercentage = (values?.autoApproveReductionPercentage ?? 0) / 100;
      nettoPrice.autoApproveReduction = values?.autoApproveReduction ?? 0;
      nettoPrice.extraReductionPercentage = (values?.extraReductionPercentage ?? 0) / 100;
      nettoPrice.extraReduction = values?.extraReduction ?? 0;
      nettoPrice.transportAndRecycle = values?.transportAndRecycle ?? 0;

      const profitPrice = { ...offer?.profitPrice };
      profitPrice.salesPrice = values?.salesPrice ?? 0;

      const currentRecord = mapRecord(values);

      return Object.assign({}, offer, {
        version: values?.version ?? `version${(currentRecord?.offers?.length ?? 0) + 1}`,
        recordId: 0,
        pdfPrice: pdfPrice,
        nettoPrice: nettoPrice,
        profitPrice: profitPrice,
        record: currentRecord,
      });
    },
    [offer, mapRecord],
  );

  const formik = useFormik<Form>({
    initialValues: {
      type: record?.type ?? '',
      numberOfTons: record?.numberOfTons ?? '',
      axles: record?.axles ?? '',
      type2: record?.type2 ?? '',
      cabin: record?.cabin ?? '',
      companyName: customer?.companyName ?? '',
      vat: customer?.vat ?? '',
      addressLine: customer?.addressLine ?? '',
      postalCode: customer?.postalCode ?? '',
      city: customer?.city ?? '',
      version: version,
      grossAmountCatalogPrice: offer?.pdfPrice?.grossAmountCatalogPrice ?? 0,
      optionsCatalogPrice: offer?.pdfPrice?.optionsCatalogPrice ?? 0,
      autoApproveReductionPercentage: (offer?.nettoPrice?.autoApproveReductionPercentage ?? 0) * 100,
      autoApproveReduction: offer?.nettoPrice?.autoApproveReduction ?? 0,
      extraReductionPercentage: (offer?.nettoPrice?.extraReductionPercentage ?? 0) * 100,
      extraReduction: offer?.nettoPrice?.extraReduction ?? 0,
      transportAndRecycle: offer?.nettoPrice?.transportAndRecycle ?? 865,
      salesPrice: offer?.profitPrice?.salesPrice ?? 0,
    },
    validationSchema: validation,
    onSubmit: (values) => {
      if (typeof file !== 'undefined') {
        dispatch(submitModel({ customer: mapCustomer(values), offer: mapOfferValues(values), record: mapRecord(values) }, file));
      }
    },
    enableReinitialize: true,
  });

  const handleCancelButton = (): void => {
    dispatch(requestRecord());
  };

  const handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    formik.handleChange(event);
  };

  useEffect((): void => {
    if (offer !== null && record?.offers !== null) {
      setVersion(
        (offer?.version ?? `version ${(record?.offers?.length ?? 0) + 1}`).trim().length > 0
          ? offer?.version ?? `version ${(record?.offers?.length ?? 0) + 1}`
          : `version ${(record?.offers?.length ?? 0) + 1}`,
      );
    } else {
      setVersion(`version 1`);
    }
  }, [offer, record]);

  const handlePercentage = (input: number): string => {
    return `${(input * 100).toFixed(2)}%`;
  };

  useEffect((): void => {
    dispatch(requestLocalWorks());
  }, [dispatch]);

  useEffect((): void => {
    if (!hasFoundCustomer) {
      dispatch(requestCustomerSearch(mapCustomer(formik.values)));
    }
  }, [dispatch, mapCustomer, formik.values, hasFoundCustomer]);

  const handleSelectCustomer = (customer: Customer): void => {
    dispatch(requestCustomerSearch(customer));
  };

  const filterTypes = (): ListItem[] =>
    lists.types.filter((f) => f.name.toLowerCase().indexOf(typeTypeahead.toLowerCase()) > -1);

  const filterNumberOfTons = (): ListItem[] =>
    lists.numberOfTons.filter((f) => f.name.toLowerCase().indexOf(numberOfTonsTypeahead.toLowerCase()) > -1);

  const filterAxles = (): ListItem[] =>
    lists.axles.filter((f) => f.name.toLowerCase().indexOf(axlesTypeahead.toLowerCase()) > -1);

  const filterType2s = (): ListItem[] =>
    lists.type2.filter((f) => f.name.toLowerCase().indexOf(type2Typeahead.toLowerCase()) > -1);

  const filterCabins = (): ListItem[] =>
    lists.cabin.filter((f) => f.name.toLowerCase().indexOf(cabinTypeahead.toLowerCase()) > -1);

  useEffect((): void => {
    const hasEmptyValue =
      formik.values.type.toString().trim().length === 0 ||
      formik.values.numberOfTons.toString().trim().length === 0 ||
      formik.values.axles.toString().trim().length === 0 ||
      formik.values.type2.toString().trim().length === 0 ||
      formik.values.cabin.toString().trim().length === 0 ||
      formik.values.grossAmountCatalogPrice.toString().trim().length === 0 ||
      formik.values.autoApproveReduction.toString().trim().length === 0 ||
      formik.values.autoApproveReductionPercentage.toString().trim().length === 0 ||
      formik.values.extraReduction.toString().trim().length === 0 ||
      formik.values.extraReductionPercentage.toString().trim().length === 0 ||
      formik.values.optionsCatalogPrice.toString().trim().length === 0 ||
      formik.values.salesPrice.toString().trim().length === 0 ||
      formik.values.transportAndRecycle.toString().trim().length === 0;

    if (typeof record !== 'undefined' && record !== null && formik.isValid && !hasEmptyValue) {
      dispatch(requestPriceCalculation(mapOfferValues(formik.values)));
    }
  }, [record, formik.values, formik.isValid, dispatch, mapOfferValues]);

  const getFilteredChosenList = (): LocalWork[] => {
    return (offer?.inclusiveLocalWorks ?? []).filter((f) => true);
  };

  return (
    <ContainerVertical>
      <div className="container">
        <h1>Offerte</h1>
          {typeof record === 'undefined' || typeof offer === 'undefined' || loading ? (
            <Loader />
          ) : (
              <form onSubmit={formik.handleSubmit}>
                <div className="customer">
                  <FormGroupContainer>
                    <FormGroupContainer>
                      <h3>Gegevens</h3>
                    </FormGroupContainer>
                    <FormGroupContainer horizontal>
                      <TextField
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        id="companyName"
                        name="companyName"
                        label="Bedrijfsnaam"
                        value={formik.values.companyName}
                        errorMessage={
                          formik.touched.companyName && Boolean(formik.errors.companyName) && formik.errors.companyName
                        }
                      />
                      <TextField
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        id="vat"
                        name="vat"
                        label="BTW-nummer"
                        value={formik.values.vat}
                        errorMessage={formik.touched.vat && Boolean(formik.errors.vat) && formik.errors.vat}
                      />
                    </FormGroupContainer>
                    <Divider margin />
                    <FormGroupContainer horizontal>
                      <TextField
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        id="addressLine"
                        name="addressLine"
                        label="Straat + huisnummer"
                        value={formik.values.addressLine}
                        errorMessage={
                          formik.touched.addressLine && Boolean(formik.errors.addressLine) && formik.errors.addressLine
                        }
                      />
                    </FormGroupContainer>
                    <FormGroupContainer horizontal>
                      <TextField
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        id="postalCode"
                        name="postalCode"
                        label="Postcode"
                        value={formik.values.postalCode}
                        errorMessage={
                          formik.touched.postalCode && Boolean(formik.errors.postalCode) && formik.errors.postalCode
                        }
                      />
                      <TextField
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        id="city"
                        name="city"
                        label="Gemeente"
                        value={formik.values.city}
                        errorMessage={formik.touched.city && Boolean(formik.errors.city) && formik.errors.city}
                      />
                    </FormGroupContainer>
                    <FormGroupContainer horizontal></FormGroupContainer>
                  </FormGroupContainer>
                  {filteredCustomers.length > 0 && (
                    <FormGroupContainer>
                      <h3>Mogelijke klanten</h3>
                      <Table striped bordered size="sm">
                        <thead>
                          <tr>
                            <th></th>
                            <th>Bedrijf</th>
                            <th>BTW-nummer</th>
                            <th>Stad</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredCustomers.map((item, key) => (
                            <tr key={`customer-${key}`}>
                              <td>
                                <Button square transparent onClick={(): void => handleSelectCustomer(item)}>
                                  <Icon>check</Icon>
                                </Button>
                              </td>
                              <td>{item.companyName}</td>
                              <td>{item.vat}</td>
                              <td>{item.city}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </FormGroupContainer>
                  )}
                </div>
                <Divider margin />
                <FormGroupContainer>
                  <FormGroupContainer>
                    <h3>Configuratie</h3>
                  </FormGroupContainer>
                  <FormGroupContainer horizontal>
                    <FormGroup>
                      <label htmlFor="type">Type</label>
                      <TypeaheadDropdown
                        buttonText={formik.values.type.trim().length === 0 ? `Type` : formik.values.type}
                        handleInputChange={(value) => setTypeTypeahead(value)}
                        list={filterTypes()}
                        listPlaceholder="Kies uw type"
                        handleOnSelect={async (value) => formik.setFieldValue('type', value.name)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <label htmlFor="numberOfTons">Tonnage</label>
                      <TypeaheadDropdown
                        buttonText={
                          formik.values.numberOfTons.trim().length === 0 ? `Tonnage` : formik.values.numberOfTons
                        }
                        handleInputChange={(value) => setNumberOfTonsTypeahead(value)}
                        list={filterNumberOfTons()}
                        listPlaceholder="Kies uw tonnage"
                        handleOnSelect={async (value) => formik.setFieldValue('numberOfTons', value.name)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <label htmlFor="axles">Assen</label>
                      <TypeaheadDropdown
                        buttonText={formik.values.axles.trim().length === 0 ? `Assen` : formik.values.axles}
                        handleInputChange={(value) => setAxlesTypeahead(value)}
                        list={filterAxles()}
                        listPlaceholder="Kies uw assen"
                        handleOnSelect={async (value) => formik.setFieldValue('axles', value.name)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <label htmlFor="type2">Type2</label>
                      <TypeaheadDropdown
                        buttonText={formik.values.type2.trim().length === 0 ? `Type 2` : formik.values.type2}
                        handleInputChange={(value) => setType2Typeahead(value)}
                        list={filterType2s()}
                        listPlaceholder="Kies uw type 2"
                        handleOnSelect={async (value) => formik.setFieldValue('type2', value.name)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <label htmlFor="cabin">Cabine</label>
                      <TypeaheadDropdown
                        buttonText={formik.values.cabin.trim().length === 0 ? `Cabine` : formik.values.cabin}
                        handleInputChange={(value) => setCabinTypeahead(value)}
                        list={filterCabins()}
                        listPlaceholder="Kies uw cabine"
                        handleOnSelect={async (value) => formik.setFieldValue('cabin', value.name)}
                      />
                    </FormGroup>
                    <FormGroup>
                      {typeof offer.manCode !== 'undefined' && offer.manCode !== null && offer.manCode.length > 0 ? (
                        <TextField name="manCode" id="manCode" label="MAN code" value={offer.manCode} disabled />
                      ) : (
                        <UploadSection
                          accept=".pdf"
                          containsFile={typeof file !== 'undefined'}
                          uploadText={`Bestand uploaden`}
                          modifier="pdf"
                          handleFileChange={(files): void => {
                            if (files && files.length > 0) {
                              setFile(files[0]);
                            }
                          }}
                        />
                      )}
                    </FormGroup>
                  </FormGroupContainer>
                </FormGroupContainer>
                <Divider margin />
                <FormGroupContainer horizontal>
                  <FormGroupContainer className="form-group-container-border">
                    <h3>C4S - Top</h3>

                    {offer.state === OfferState.Draft ? (
                      <TextField
                        leftEl={<span dangerouslySetInnerHTML={{ __html: '&euro;' }} />}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        id="grossAmountCatalogPrice"
                        name="grossAmountCatalogPrice"
                        label="Bruto catalogusprijs"
                        type="number"
                        value={formik.values.grossAmountCatalogPrice}
                        errorMessage={
                          formik.touched.grossAmountCatalogPrice &&
                          Boolean(formik.errors.grossAmountCatalogPrice) &&
                          formik.errors.grossAmountCatalogPrice
                        }
                        disabled={offer.state !== OfferState.Draft}
                      />
                    ) : (
                      <TextField
                        label="Bruto catalogusprijs"
                        value={`€ ${offer.pdfPrice.grossAmountCatalogPrice}`}
                        disabled
                      />
                    )}
                    {offer.state === OfferState.Draft ? (
                      <TextField
                        leftEl={<span dangerouslySetInnerHTML={{ __html: '&euro;' }} />}
                        onChange={handlePriceChange}
                        onBlur={formik.handleBlur}
                        id="optionsCatalogPrice"
                        name="optionsCatalogPrice"
                        label="Somopties - onderdeel van de grote catalogusprijs"
                        type="number"
                        value={formik.values.optionsCatalogPrice}
                        errorMessage={
                          formik.touched.optionsCatalogPrice &&
                          Boolean(formik.errors.optionsCatalogPrice) &&
                          formik.errors.optionsCatalogPrice
                        }
                        disabled={offer.state !== OfferState.Draft}
                      />
                    ) : (
                      <TextField
                        label="Somopties - onderdeel van de grote catalogusprijs"
                        value={`€ ${offer.pdfPrice.optionsCatalogPrice}`}
                        disabled
                      />
                    )}

                    <FormGroupContainer horizontal>
                      <TextField
                        label="Standaardkorting percentage"
                        value={handlePercentage(offer.pdfPrice.defaultReductionPercentage)}
                        disabled
                      />
                      <TextField label="Standaardkorting" value={`€ ${offer.pdfPrice.defaultReduction}`} disabled />
                    </FormGroupContainer>

                    <FormGroupContainer horizontal>
                      <TextField
                        label="Berekende korting percentage"
                        value={handlePercentage(offer.pdfPrice.calculatedReductionPercentage)}
                        disabled
                      />
                      <TextField label="Berekende korting" value={`€ ${offer.pdfPrice.calculatedReduction}`} disabled />
                    </FormGroupContainer>

                    <FormGroupContainer horizontal>
                      <TextField
                        label="Speciale korting percentage"
                        value={handlePercentage(offer.pdfPrice.specialReductionPercentage)}
                        disabled
                      />
                      <TextField label="Speciale korting" value={`€ ${offer.pdfPrice.specialReduction}`} disabled />
                    </FormGroupContainer>

                    <FormGroupContainer horizontal>
                      <TextField
                        label="Actiekorting percentage"
                        value={handlePercentage(offer.pdfPrice.actionReductionPercentage)}
                        disabled
                      />
                      <TextField label="Actiekorting" value={`€ ${offer.pdfPrice.actionReduction}`} disabled />
                    </FormGroupContainer>

                    <TextField label="Lokale extra uitrustingen" value={`€ ${offer.pdfPrice.localExtra}`} disabled />
                    <TextField label="Vasteprijsopties" value={`€ ${offer.pdfPrice.defaultPriceOptions}`} disabled />
                    <TextField label="Nettoprijs" value={`€ ${offer.pdfPrice.nettoPrice}`} disabled />
                    <TextField label="Totaal bruto" value={`€ ${offer.pdfPrice.totalGrossAmount}`} disabled />
                    <TextField label="Totale korting" value={`€ ${offer.pdfPrice.totalReduction}`} disabled />
                    <TextField label="Eindprijs klant" value={`€ ${offer.pdfPrice.customerPrice}`} disabled />
                  </FormGroupContainer>
                  <FormGroupContainer className="form-group-container-border">
                    <h3>Nettoberekening</h3>
                    <TextField label="Basisprijs BLP" className="text-input-highlight" value={`€ ${offer.nettoPrice.basic}`} disabled />
                    <TextField label="Opties" className="text-input-highlight" value={`€ ${offer.nettoPrice.options}`} disabled />
                    <TextField label="Opties (Banden)" value={`€ ${offer.nettoPrice.optionsWheels}`} disabled />
                    <TextField label="Opties (Kleur)" value={`€ ${offer.nettoPrice.optionsColor}`} disabled />
                    <TextField label="BLP incl. opties" value={`€ ${offer.nettoPrice.priceWithOptions}`} disabled />
                    <FormGroupContainer horizontal>
                      <TextField
                        label="Standaardkorting percentage"
                        value={handlePercentage(offer.nettoPrice.defaultReductionPercentage)}
                        disabled
                      />
                      <TextField label="Standaardkorting" value={`€ ${offer.nettoPrice.defaultReduction}`} disabled />
                    </FormGroupContainer>

                    <FormGroupContainer horizontal>
                      {offer.state === OfferState.Draft ? (
                        <TextField
                          rightEl={<span dangerouslySetInnerHTML={{ __html: '%' }} />}
                          onChange={handlePriceChange}
                          disabled={offer.state !== OfferState.Draft}
                          onBlur={formik.handleBlur}
                          id="autoApproveReductionPercentage"
                          name="autoApproveReductionPercentage"
                          label="Korting - auto approve percentage"
                          type="number"
                          value={formik.values.autoApproveReductionPercentage}
                          errorMessage={
                            formik.touched.autoApproveReductionPercentage &&
                            Boolean(formik.errors.autoApproveReductionPercentage) &&
                            formik.errors.autoApproveReductionPercentage
                          }
                        />
                      ) : (
                        <TextField
                          label="Korting - auto approve percentage"
                          value={`${offer.nettoPrice.autoApproveReductionPercentage} %`}
                          disabled
                        />
                      )}

                      {offer.state === OfferState.Draft ? (
                        <TextField
                          leftEl={<span dangerouslySetInnerHTML={{ __html: '&euro;' }} />}
                          onChange={handlePriceChange}
                          disabled={offer.state !== OfferState.Draft}
                          onBlur={formik.handleBlur}
                          id="autoApproveReduction"
                          name="autoApproveReduction"
                          label="Korting - auto approve"
                          type="number"
                          value={formik.values.autoApproveReduction}
                          errorMessage={
                            formik.touched.autoApproveReduction &&
                            Boolean(formik.errors.autoApproveReduction) &&
                            formik.errors.autoApproveReduction
                          }
                        />
                      ) : (
                        <TextField
                          label="Korting - auto approve"
                          value={`€ ${offer.nettoPrice.autoApproveReduction}`}
                          disabled
                        />
                      )}
                    </FormGroupContainer>

                    <FormGroupContainer horizontal>
                      {offer.state === OfferState.Draft ? (
                        <TextField
                          rightEl={<span dangerouslySetInnerHTML={{ __html: '%' }} />}
                          onChange={handlePriceChange}
                          onBlur={formik.handleBlur}
                          id="extraReductionPercentage"
                          name="extraReductionPercentage"
                          label="Extra tussenkomst percentage"
                          type="number"
                          value={formik.values.extraReductionPercentage}
                          errorMessage={
                            formik.touched.extraReductionPercentage &&
                            Boolean(formik.errors.extraReductionPercentage) &&
                            formik.errors.extraReductionPercentage
                          }
                          disabled={offer.state !== OfferState.Draft}
                        />
                      ) : (
                        <TextField
                          label="Extra tussenkomst percentage"
                          value={`${offer.nettoPrice.extraReductionPercentage} %`}
                          disabled
                        />
                      )}

                      {offer.state === OfferState.Draft ? (
                        <TextField
                          leftEl={<span dangerouslySetInnerHTML={{ __html: '&euro;' }} />}
                          onChange={handlePriceChange}
                          onBlur={formik.handleBlur}
                          id="extraReduction"
                          name="extraReduction"
                          label="Extra tussenkomst"
                          type="number"
                          value={formik.values.extraReduction}
                          errorMessage={
                            formik.touched.extraReduction &&
                            Boolean(formik.errors.extraReduction) &&
                            formik.errors.extraReduction
                          }
                          disabled={offer.state !== OfferState.Draft}
                        />
                      ) : (
                        <TextField label="Extra tussenkomst" value={`€ ${offer.nettoPrice.extraReduction}`} disabled />
                      )}
                    </FormGroupContainer>

                    <TextField
                      label="Transport en recyclage kosten"
                      value={`€ ${offer.nettoPrice.transportAndRecycle}`}
                      disabled
                    />
                    <TextField
                      label="Dealer Nettoprijs - Price Generator"
                      value={`€ ${offer.nettoPrice.dealerPrice}`}
                      disabled
                    />
                    <TextField
                      label="Dealer Nettoprijs - Extra tussenkomt"
                      value={`€ ${offer.nettoPrice.dealerPriceWithExtraReduction}`}
                      disabled
                    />
                  </FormGroupContainer>
                  <FormGroupContainer className="form-group-container-border">
                      <h3>Marge berekening</h3>
                      {offer.state === OfferState.Draft ? (
                        <TextField
                          leftEl={<span dangerouslySetInnerHTML={{ __html: '&euro;' }} />}
                          onChange={handlePriceChange}
                          onBlur={formik.handleBlur}
                          id="salesPrice"
                          name="salesPrice"
                          label="Verkoopsprijs"
                          type="number"
                          value={formik.values.salesPrice}
                          errorMessage={
                            formik.touched.salesPrice && Boolean(formik.errors.salesPrice) && formik.errors.salesPrice
                          }
                          disabled={offer.state !== OfferState.Draft}
                        />
                      ) : (
                        <TextField label="Verkoopsprijs" value={`€ ${offer.profitPrice.salesPrice}`} disabled />
                      )}
                      <FormGroupContainer horizontal>
                        <TextField
                          label="Marge percentage"
                          value={handlePercentage(offer.profitPrice.percentage)}
                          disabled
                        />
                        <TextField label="Marge" value={`€ ${offer.profitPrice.profit}`} disabled />
                      </FormGroupContainer>
                    <Divider margin />
                    <h3>Inclusieve Lokale werken</h3>
                    <Table responsive striped bordered size="sm">
                      <thead>
                        <tr>
                          <th>Naam</th>
                        </tr>
                      </thead>
                      <tbody>
                        {_.orderBy(getFilteredChosenList(), ['id']).map((localWork, key) => (
                          <tr key={`offer-local-work-${key}`}>
                            <td>
                              <Button
                                square
                                transparent
                                onClick={(): void => {
                                  dispatch(removeIncludedOfferLocalWork(localWork));
                                }}
                              >
                                <Icon>close</Icon>
                              </Button>
                            </td>
                            <td dangerouslySetInnerHTML={{ __html: localWork.name }} />
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <Button onClick={(): void => setInclusiveLocalWorksOpen(true)} primary>
                      Kies lokale werken
                    </Button>
                    <Divider margin />
                    <h3>Status</h3>
                    <Divider margin />
                    <ButtonGroup>
                      <Button secondary type="reset" onClick={handleCancelButton}>
                        Annuleren
                      </Button>
                      <Button primary type="submit">
                        Opslaan
                      </Button>
                    </ButtonGroup>
                  </FormGroupContainer>
                </FormGroupContainer>
              </form>
          )}
      </div>
      <LocalWorksModal handleClose={(): void => setInclusiveLocalWorksOpen(false)} isOpen={isInclusiveLocalWorksOpen} />
    </ContainerVertical>
  );
};

export default Edit;
