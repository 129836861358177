export const INITIALIZE_APPLICATION = `INITIALIZE_APPLICATION`;

// records
export const REQUEST_RECORDS = `REQUEST_RECORDS`;
export const RECEIVE_RECORDS = `RECEIVE_RECORDS`;
export const RECORDS_ERROR = `RECORDS_ERROR`;

// record
export const REQUEST_RECORD = `REQUEST_RECORD`;
export const RECEIVE_RECORD = `RECEIVE_RECORD`;
export const RECORD_ERROR = `RECORD_ERROR`;
export const SUBMIT_RECORD = `SUBMIT_RECORD`;

export const REQUEST_RECORD_OFFERS = `REQUEST_RECORD_OFFERS`;
export const RECEIVE_RECORD_OFFERS = `RECEIVE_RECORD_OFFERS`;

// offer
export const REQUEST_OFFER = `REQUEST_OFFER`;
export const RECEIVE_OFFER = `RECEIVE_OFFER`;
export const OFFER_ERROR = `OFFER_ERROR`;
export const SUBMIT_OFFER = `SUBMIT_OFFER`;

export const REQUEST_PRICE_CALCULATION = `REQUEST_PRICE_CALCULATION`;
export const RECEIVE_PRICE_CALCULATION = `RECEIVE_PRICE_CALCULATION`;
export const PRICE_CALCULATION_ERROR = `PRICE_CALCULATION_ERROR`;

// generator
export const REQUEST_GENERATE_OFFER = `REQUEST_GENERATE_OFFER`;

// local-works
export const REQUEST_LOCAL_WORKS = `REQUEST_LOCAL_WORKS`;
export const RECEIVE_LOCAL_WORKS = `RECEIVE_LOCAL_WORKS`;
export const LOCAL_WORKS_ERROR = `LOCAL_WORKS_ERROR`;

// local-work
export const PUSH_INCLUDED_OFFER_LOCAL_WORK = `PUSH_INCLUDED_OFFER_LOCAL_WORK`;
export const REMOVE_INCLUDED_OFFER_LOCAL_WORK = `REMOVE_INCLUDED_OFFER_LOCAL_WORK`;
export const PUSH_OPTIONAL_OFFER_LOCAL_WORK = `PUSH_OPTIONAL_OFFER_LOCAL_WORK`;
export const REMOVE_OPTIONAL_OFFER_LOCAL_WORK = `REMOVE_OPTIONAL_OFFER_LOCAL_WORK`;
export const REQUEST_LOCAL_WORK = `REQUEST_LOCAL_WORK`;
export const RECEIVE_LOCAL_WORK = `RECEIVE_LOCAL_WORK`;
export const LOCAL_WORK_ERROR = `LOCAL_WORK_ERROR`;
export const SUBMIT_LOCAL_WORK = `SUBMIT_LOCAL_WORK`;

// customers
export const REQUEST_CUSTOMERS = `REQUEST_CUSTOMERS`;
export const RECEIVE_CUSTOMERS = `RECEIVE_CUSTOMERS`;
export const CUSTOMERS_ERROR = `CUSTOMERS_ERROR`;

// customer
export const REQUEST_CUSTOMER = `REQUEST_CUSTOMER`;
export const RECEIVE_CUSTOMER = `RECEIVE_CUSTOMER`;
export const CUSTOMER_ERROR = `CUSTOMER_ERROR`;
export const SUBMIT_CUSTOMER = `SUBMIT_CUSTOMER`;
export const REQUEST_CUSTOMER_SEARCH = `REQUEST_CUSTOMER_SEARCH`;
export const RECEIVE_CUSTOMERS_SEARCH = `RECEIVE_CUSTOMERS_SEARCH`;
export const RECEIVE_CUSTOMER_SEARCH = `RECEIVE_CUSTOMER_SEARCH`;

// record-state
export const REQUEST_RECORD_STATE = `REQUEST_RECORD_STATE`;
export const RECEIVE_RECORD_STATE = `RECEIVE_RECORD_STATE`;
export const RECORD_STATE_ERROR = `RECORD_STATE_ERROR`;
export const SUBMIT_RECORD_STATE = `SUBMIT_RECORD_STATE`;


// models
export const SUBMIT_MODEL = `SUBMIT_MODEL`;