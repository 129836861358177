import { OfferState } from '../../types/states';
import { Actions, PushIncludedOfferLocalWork, PushOptionalOfferLocalWork, RemoveIncludedOfferLocalWork, RemoveOptionalOfferLocalWork } from '../../types/actions';
import * as types from '../../types/constants';

const initialValues: OfferState = {
  loading: false,
  error: false,
};

const whenPushIncludedOfferLocalWork = (state = initialValues, action: PushIncludedOfferLocalWork): OfferState => {
  if (typeof state.offer === 'undefined') {
    return state;
  }

  const offer = state.offer;
  offer.inclusiveLocalWorks.push(action.localWork);

  return Object.assign({}, state, {
    loading: false,
    offer: { ...offer },
    error: false,
  });
};

const whenRemoveIncludedOfferLocalWork = (state = initialValues, action: RemoveIncludedOfferLocalWork): OfferState => {
  if (typeof state.offer === 'undefined') {
    return state;
  }

  const offer = state.offer;
  offer.inclusiveLocalWorks = offer.inclusiveLocalWorks.filter((localWork) => localWork.id !== action.localWork.id);

  return Object.assign({}, state, {
    loading: false,
    offer: { ...offer },
    error: false,
  });
};

const whenPushOptionalOfferLocalWork = (state = initialValues, action: PushOptionalOfferLocalWork): OfferState => {
  if (typeof state.offer === 'undefined') {
    return state;
  }

  const offer = state.offer;
  offer.optionalLocalWorks.push(action.localWork);

  return Object.assign({}, state, {
    loading: false,
    offer: { ...offer },
    error: false,
  });
};

const whenRemoveOptionalOfferLocalWork = (state = initialValues, action: RemoveOptionalOfferLocalWork): OfferState => {
  if (typeof state.offer === 'undefined') {
    return state;
  }

  const offer = state.offer;
  offer.optionalLocalWorks = offer.optionalLocalWorks.filter((localWork) => localWork.id !== action.localWork.id);

  return Object.assign({}, state, {
    loading: false,
    offer: { ...offer },
    error: false,
  });
};

const offers = (state = initialValues, action: Actions): OfferState => {
  switch (action.type) {
    case types.REQUEST_OFFER:
      return Object.assign({}, state, {
        loading: true,
        error: false,
      });
    case types.RECEIVE_OFFER:
      return Object.assign({}, state, {
        loading: false,
        offer: action.offer,
        error: false,
      });
    case types.RECEIVE_PRICE_CALCULATION:
      if (JSON.stringify(state.offer) === JSON.stringify(action.offer)) {
        return Object.assign({}, state, {
          loading: false,
          error: false,
        });
      }
      return Object.assign({}, state, {
        loading: false,
        offer: action.offer,
        error: false,
      });
    case types.OFFER_ERROR:
      return Object.assign({}, state, {
        loading: false,
        error: true,
      });
    case types.SUBMIT_OFFER:
      return Object.assign({}, state, {
        loading: true,
        offer: action.offer,
        error: false,
      });
    case types.PUSH_INCLUDED_OFFER_LOCAL_WORK:
      return whenPushIncludedOfferLocalWork(state, action);
    case types.REMOVE_INCLUDED_OFFER_LOCAL_WORK:
      return whenRemoveIncludedOfferLocalWork(state, action);
      case types.PUSH_OPTIONAL_OFFER_LOCAL_WORK:
      return whenPushOptionalOfferLocalWork(state, action);
    case types.REMOVE_OPTIONAL_OFFER_LOCAL_WORK:
      return whenRemoveOptionalOfferLocalWork(state, action);
    default:
      return state;
  }
};

export default offers;
