import React, { FC } from 'react';
import { TopBar, TopBarLink, TopBarLinkContainer, TopBarSection, TopBarTitle, Button } from '@duik/it';
import { Link } from 'react-router-dom';

const NavigationBar: FC = () => (
  <TopBar>
    <TopBarSection>
      <TopBarTitle>Betracon Offertool</TopBarTitle>
    </TopBarSection>
    <TopBarSection>
      <TopBarLinkContainer>
        <TopBarLink Component={Link} to="/">
          Offerte aanmaken
        </TopBarLink>
        <TopBarLink Component={Link} to="/customers">
          Klanten
        </TopBarLink>
        <TopBarLink Component={Link} to="/records">
          Dossiers
        </TopBarLink>
        <TopBarLink Component={Link} to="/local-works">
          Lokale werken
        </TopBarLink>
        <Button primary Component={Link} to="/" >Offerte aanmaken</Button>
      </TopBarLinkContainer>
    </TopBarSection>
  </TopBar>
);

export default NavigationBar;
