import { ListItem } from '../../types/models';

export const types: ListItem[] = [
  {
    id: 0,
    name: 'eTGE',
  },
  {
    id: 0,
    name: 'NTGL',
  },
  {
    id: 0,
    name: 'NTGM',
  },
  {
    id: 0,
    name: 'NTGS',
  },
  {
    id: 0,
    name: 'NTGX',
  },
  {
    id: 0,
    name: 'TGE',
  },
  {
    id: 0,
    name: 'TGL',
  },
  {
    id: 0,
    name: 'TGM',
  },
  {
    id: 0,
    name: 'TGS',
  },
  {
    id: 0,
    name: 'TGX',
  },
];

export const numberOfTons: ListItem[] = [
  {
    id: 0,
    name: '2.100',
  },
  {
    id: 0,
    name: '2.140',
  },
  {
    id: 0,
    name: '2.180',
  },
  {
    id: 0,
    name: '3.100',
  },
  {
    id: 0,
    name: '3.140',
  },
  {
    id: 0,
    name: '3.180',
  },
  {
    id: 0,
    name: '5.120',
  },
  {
    id: 0,
    name: '5.180',
  },
  {
    id: 0,
    name: '6.120',
  },
  {
    id: 0,
    name: '6.180',
  },
  {
    id: 0,
    name: '7.150',
  },
  {
    id: 0,
    name: '7.160',
  },
  {
    id: 0,
    name: '7.180',
  },
  {
    id: 0,
    name: '7.190',
  },
  {
    id: 0,
    name: '8.160',
  },
  {
    id: 0,
    name: '8.180',
  },
  {
    id: 0,
    name: '8.190',
  },
  {
    id: 0,
    name: '8.210',
  },
  {
    id: 0,
    name: '8.220',
  },
  {
    id: 0,
    name: '8.240',
  },
  {
    id: 0,
    name: '8.250',
  },
  {
    id: 0,
    name: '10.160',
  },
  {
    id: 0,
    name: '10.180',
  },
  {
    id: 0,
    name: '10.190',
  },
  {
    id: 0,
    name: '10.210',
  },
  {
    id: 0,
    name: '10.220',
  },
  {
    id: 0,
    name: '10.240',
  },
  {
    id: 0,
    name: '10.250',
  },
  {
    id: 0,
    name: '12.160',
  },
  {
    id: 0,
    name: '12.180',
  },
  {
    id: 0,
    name: '12.190',
  },
  {
    id: 0,
    name: '12.210',
  },
  {
    id: 0,
    name: '12.220',
  },
  {
    id: 0,
    name: '12.240',
  },
  {
    id: 0,
    name: '12.250',
  },
  {
    id: 0,
    name: '12.280',
  },
  {
    id: 0,
    name: '12.290',
  },
  {
    id: 0,
    name: '13.240',
  },
  {
    id: 0,
    name: '13.250',
  },
  {
    id: 0,
    name: '13.280',
  },
  {
    id: 0,
    name: '13.290',
  },
  {
    id: 0,
    name: '15.240',
  },
  {
    id: 0,
    name: '15.250',
  },
  {
    id: 0,
    name: '15.280',
  },
  {
    id: 0,
    name: '15.290',
  },
  {
    id: 0,
    name: '15.330',
  },
  {
    id: 0,
    name: '15.340',
  },
  {
    id: 0,
    name: '15.360',
  },
  {
    id: 0,
    name: '15.400',
  },
  {
    id: 0,
    name: '15.420',
  },
  {
    id: 0,
    name: '15.430',
  },
  {
    id: 0,
    name: '15.440',
  },
  {
    id: 0,
    name: '15.460',
  },
  {
    id: 0,
    name: '15.470',
  },
  {
    id: 0,
    name: '15.480',
  },

  {
    id: 0,
    name: '15.500',
  },
  {
    id: 0,
    name: '15.510',
  },
  {
    id: 0,
    name: '15.520',
  },
  {
    id: 0,
    name: '15.540',
  },
  {
    id: 0,
    name: '15.560',
  },
  {
    id: 0,
    name: '15.580',
  },
  {
    id: 0,
    name: '15.640',
  },
  {
    id: 0,
    name: '15.680',
  },
  {
    id: 0,
    name: '18.240',
  },
  {
    id: 0,
    name: '18.250',
  },
  {
    id: 0,
    name: '18.280',
  },
  {
    id: 0,
    name: '18.290',
  },
  {
    id: 0,
    name: '18.330',
  },
  {
    id: 0,
    name: '18.340',
  },
  {
    id: 0,
    name: '18.360',
  },
  {
    id: 0,
    name: '18.400',
  },
  {
    id: 0,
    name: '18.420',
  },
  {
    id: 0,
    name: '18.430',
  },
  {
    id: 0,
    name: '18.440',
  },
  {
    id: 0,
    name: '18.460',
  },
  {
    id: 0,
    name: '18.470',
  },
  {
    id: 0,
    name: '18.480',
  },

  {
    id: 0,
    name: '18.500',
  },
  {
    id: 0,
    name: '18.510',
  },
  {
    id: 0,
    name: '18.520',
  },
  {
    id: 0,
    name: '18.540',
  },
  {
    id: 0,
    name: '18.560',
  },
  {
    id: 0,
    name: '18.580',
  },
  {
    id: 0,
    name: '18.640',
  },
  {
    id: 0,
    name: '18.680',
  },
  {
    id: 0,
    name: '19.360',
  },
  {
    id: 0,
    name: '19.400',
  },
  {
    id: 0,
    name: '19.440',
  },
  {
    id: 0,
    name: '19.480',
  },
  {
    id: 0,
    name: '21.360',
  },
  {
    id: 0,
    name: '21.400',
  },
  {
    id: 0,
    name: '21.440',
  },
  {
    id: 0,
    name: '21.480',
  },
  {
    id: 0,
    name: '22.240',
  },
  {
    id: 0,
    name: '22.250',
  },
  {
    id: 0,
    name: '22.280',
  },
  {
    id: 0,
    name: '22.290',
  },
  {
    id: 0,
    name: '22.320',
  },
  {
    id: 0,
    name: '23.360',
  },
  {
    id: 0,
    name: '23.420',
  },
  {
    id: 0,
    name: '23.430',
  },
  {
    id: 0,
    name: '23.440',
  },
  {
    id: 0,
    name: '23.460',
  },
  {
    id: 0,
    name: '23.470',
  },
  {
    id: 0,
    name: '23.480',
  },

  {
    id: 0,
    name: '23.500',
  },
  {
    id: 0,
    name: '23.510',
  },
  {
    id: 0,
    name: '24.320',
  },
  {
    id: 0,
    name: '24.330',
  },
  {
    id: 0,
    name: '24.360',
  },
  {
    id: 0,
    name: '24.400',
  },
  {
    id: 0,
    name: '24.420',
  },
  {
    id: 0,
    name: '24.430',
  },
  {
    id: 0,
    name: '24.440',
  },
  {
    id: 0,
    name: '24.460',
  },
  {
    id: 0,
    name: '24.470',
  },
  {
    id: 0,
    name: '24.480',
  },

  {
    id: 0,
    name: '24.500',
  },
  {
    id: 0,
    name: '24.510',
  },
  {
    id: 0,
    name: '26.280',
  },
  {
    id: 0,
    name: '26.290',
  },
  {
    id: 0,
    name: '26.320',
  },
  {
    id: 0,
    name: '26.330',
  },
  {
    id: 0,
    name: '26.360',
  },
  {
    id: 0,
    name: '26.400',
  },
  {
    id: 0,
    name: '26.420',
  },
  {
    id: 0,
    name: '26.430',
  },
  {
    id: 0,
    name: '26.440',
  },
  {
    id: 0,
    name: '26.460',
  },
  {
    id: 0,
    name: '26.470',
  },
  {
    id: 0,
    name: '26.480',
  },

  {
    id: 0,
    name: '26.500',
  },
  {
    id: 0,
    name: '26.510',
  },
  {
    id: 0,
    name: '26.520',
  },
  {
    id: 0,
    name: '26.540',
  },
  {
    id: 0,
    name: '26.560',
  },
  {
    id: 0,
    name: '26.580',
  },
  {
    id: 0,
    name: '26.640',
  },
  {
    id: 0,
    name: '28.320',
  },
  {
    id: 0,
    name: '28.330',
  },
  {
    id: 0,
    name: '28.360',
  },
  {
    id: 0,
    name: '28.400',
  },
  {
    id: 0,
    name: '28.420',
  },
  {
    id: 0,
    name: '28.430',
  },
  {
    id: 0,
    name: '28.440',
  },
  {
    id: 0,
    name: '28.460',
  },
  {
    id: 0,
    name: '28.470',
  },
  {
    id: 0,
    name: '28.480',
  },

  {
    id: 0,
    name: '28.500',
  },
  {
    id: 0,
    name: '28.510',
  },
  {
    id: 0,
    name: '28.520',
  },
  {
    id: 0,
    name: '28.540',
  },
  {
    id: 0,
    name: '28.560',
  },
  {
    id: 0,
    name: '28.580',
  },
  {
    id: 0,
    name: '32.320',
  },
  {
    id: 0,
    name: '32.330',
  },
  {
    id: 0,
    name: '32.360',
  },
  {
    id: 0,
    name: '32.400',
  },
  {
    id: 0,
    name: '32.420',
  },
  {
    id: 0,
    name: '32.430',
  },
  {
    id: 0,
    name: '32.440',
  },
  {
    id: 0,
    name: '32.460',
  },
  {
    id: 0,
    name: '32.470',
  },
  {
    id: 0,
    name: '32.480',
  },
  {
    id: 0,
    name: '32.500',
  },
  {
    id: 0,
    name: '32.510',
  },
  {
    id: 0,
    name: '33.320',
  },
  {
    id: 0,
    name: '33.330',
  },
  {
    id: 0,
    name: '33.360',
  },
  {
    id: 0,
    name: '33.400',
  },
  {
    id: 0,
    name: '33.420',
  },
  {
    id: 0,
    name: '33.430',
  },
  {
    id: 0,
    name: '33.440',
  },
  {
    id: 0,
    name: '33.460',
  },
  {
    id: 0,
    name: '33.470',
  },
  {
    id: 0,
    name: '33.480',
  },

  {
    id: 0,
    name: '33.500',
  },
  {
    id: 0,
    name: '33.510',
  },
  {
    id: 0,
    name: '33.520',
  },
  {
    id: 0,
    name: '33.540',
  },
  {
    id: 0,
    name: '33.560',
  },
  {
    id: 0,
    name: '33.580',
  },
  {
    id: 0,
    name: '33.640',
  },
  {
    id: 0,
    name: '33.680',
  },
  {
    id: 0,
    name: '35.320',
  },
  {
    id: 0,
    name: '35.330',
  },
  {
    id: 0,
    name: '35.360',
  },
  {
    id: 0,
    name: '35.400',
  },
  {
    id: 0,
    name: '35.420',
  },
  {
    id: 0,
    name: '35.430',
  },
  {
    id: 0,
    name: '35.440',
  },
  {
    id: 0,
    name: '35.460',
  },
  {
    id: 0,
    name: '35.470',
  },
  {
    id: 0,
    name: '35.480',
  },

  {
    id: 0,
    name: '35.500',
  },
  {
    id: 0,
    name: '35.510',
  },
  {
    id: 0,
    name: '35.520',
  },
  {
    id: 0,
    name: '35.540',
  },
  {
    id: 0,
    name: '35.560',
  },
  {
    id: 0,
    name: '35.580',
  },
  {
    id: 0,
    name: '35.640',
  },
  {
    id: 0,
    name: '37.420',
  },
  {
    id: 0,
    name: '37.430',
  },
  {
    id: 0,
    name: '37.440',
  },
  {
    id: 0,
    name: '37.460',
  },
  {
    id: 0,
    name: '37.470',
  },
  {
    id: 0,
    name: '37.480',
  },
  {
    id: 0,
    name: '37.500',
  },
  {
    id: 0,
    name: '37.510',
  },
  {
    id: 0,
    name: '40.360',
  },
  {
    id: 0,
    name: '40.400',
  },
  {
    id: 0,
    name: '40.420',
  },
  {
    id: 0,
    name: '40.480',
  },
  {
    id: 0,
    name: '41.320',
  },
  {
    id: 0,
    name: '41.330',
  },
  {
    id: 0,
    name: '41.360',
  },
  {
    id: 0,
    name: '41.400',
  },
  {
    id: 0,
    name: '41.420',
  },
  {
    id: 0,
    name: '41.430',
  },
  {
    id: 0,
    name: '41.440',
  },
  {
    id: 0,
    name: '41.460',
  },
  {
    id: 0,
    name: '41.470',
  },
  {
    id: 0,
    name: '41.480',
  },

  {
    id: 0,
    name: '41.500',
  },
  {
    id: 0,
    name: '41.510',
  },
  {
    id: 0,
    name: '41.520',
  },
  {
    id: 0,
    name: '41.540',
  },
  {
    id: 0,
    name: '41.560',
  },
  {
    id: 0,
    name: '41.580',
  },
  {
    id: 0,
    name: '41.640',
  },
  {
    id: 0,
    name: '41.680',
  },
];

export const axles: ListItem[] = [
  {
    id: 0,
    name: '4x2',
  },
  {
    id: 0,
    name: '4x2 FWD',
  },
  {
    id: 0,
    name: '4x2 FWD 8',
  },
  {
    id: 0,
    name: '4x2 RWD',
  },
  {
    id: 0,
    name: '4x2 RWD 8',
  },
  {
    id: 0,
    name: '4x2 RWD 8 S',
  },
  {
    id: 0,
    name: '4x4',
  },
  {
    id: 0,
    name: '4x4 8',
  },
  {
    id: 0,
    name: '4x4H',
  },
  {
    id: 0,
    name: '6X2/2',
  },
  {
    id: 0,
    name: '6X2/4',
  },
  {
    id: 0,
    name: '6X2-2',
  },
  {
    id: 0,
    name: '6X2-4',
  },
  {
    id: 0,
    name: '6X4',
  },
  {
    id: 0,
    name: '6X4H/2',
  },
  {
    id: 0,
    name: '6X4H/4',
  },
  {
    id: 0,
    name: '6X4H-2',
  },
  {
    id: 0,
    name: '6X4H-4',
  },
  {
    id: 0,
    name: '6X6',
  },
  {
    id: 0,
    name: '6X6H',
  },
  {
    id: 0,
    name: '8X2-4',
  },
  {
    id: 0,
    name: '8X4',
  },
  {
    id: 0,
    name: '8X4-4',
  },
  {
    id: 0,
    name: '8X4H-6',
  },
  {
    id: 0,
    name: '8X6',
  },
  {
    id: 0,
    name: '8X6H',
  },
  {
    id: 0,
    name: '8X8',
  },
];

export const type2: ListItem[] = [
  {
    id: 0,
    name: 'BB',
  },
  {
    id: 0,
    name: 'BB-WW',
  },
  {
    id: 0,
    name: 'BBS',
  },
  {
    id: 0,
    name: 'BBS-WW',
  },
  {
    id: 0,
    name: 'BL',
  },
  {
    id: 0,
    name: 'BL-WW',
  },
  {
    id: 0,
    name: 'BLS',
  },
  {
    id: 0,
    name: 'BLS-WW',
  },
  {
    id: 0,
    name: 'BS',
  },
  {
    id: 0,
    name: 'Ch-Cab',
  },
  {
    id: 0,
    name: 'Ch-DoCab',
  },
  {
    id: 0,
    name: 'LL',
  },
  {
    id: 0,
    name: 'LLS',
  },
  {
    id: 0,
    name: 'LL-U',
  },
  {
    id: 0,
    name: 'Panel',
  },
];

export const cabin: ListItem[] = [
  {
    id: 0,
    name: 'C',
  },
  {
    id: 0,
    name: 'CC',
  },
  {
    id: 0,
    name: 'D',
  },
  {
    id: 0,
    name: 'DN',
  },
  {
    id: 0,
    name: 'TM',
  },
  {
    id: 0,
    name: 'TN',
  },
  {
    id: 0,
    name: 'GM',
  },
  {
    id: 0,
    name: 'GN',
  },
  {
    id: 0,
    name: 'GX',
  },
  {
    id: 0,
    name: 'L',
  },
  {
    id: 0,
    name: 'L3',
  },
  {
    id: 0,
    name: 'L4',
  },
  {
    id: 0,
    name: 'L5',
  },
  {
    id: 0,
    name: 'LX',
  },
  {
    id: 0,
    name: 'M',
  },
  {
    id: 0,
    name: 'MN',
  },
  {
    id: 0,
    name: 'XL',
  },
  {
    id: 0,
    name: 'XLX',
  },
  {
    id: 0,
    name: 'XXL',
  },
];
