import React, { FC, useEffect } from 'react';
import ContainerVertical from '@duik/container-vertical';
import ScrollArea from '@duik/scroll-area';
import Button from '@duik/button';
import Table from 'react-bootstrap/Table';
import Icon from '@duik/icon';
import { Link } from 'react-router-dom';
import { useBetraconSelector } from '../../reducers';
import { useDispatch } from 'react-redux';
import { requestRecords } from '../../actions';

import Loader from '../../components/Loader';
import ContainerHorizontal from '@duik/container-horizontal';
import { RecordState, Record } from '../../types/models';
const List: FC = () => {
  const { records, loading } = useBetraconSelector((state) => state.records);
  const dispatch = useDispatch();

  const getFilteredRecords = (state: RecordState): Record[] => records.filter(record => (record.state?.state ?? RecordState.Pending) === state)

  useEffect(() => {
    dispatch(requestRecords());
  }, [dispatch]);
  return (
    <ContainerVertical>
      <ScrollArea>
        <div className="container">
          <h1>Dossiers</h1>
          {loading ? (
            <Loader />
          ) : (
            <ContainerHorizontal>
              <ContainerVertical className="container__records">
                <h2>Pending</h2>
                {getFilteredRecords(RecordState.Pending).length == 0 ? <span>Er zijn geen pending dossiers</span> : (<Table striped bordered size="sm">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Company</th>
                      <th>Type</th>
                      <th>Tonnage</th>
                      <th>Assen</th>
                      <th>Type2</th>
                      <th>Cabine</th>
                    </tr>
                  </thead>
                  <tbody>
                    {getFilteredRecords(RecordState.Pending).map((record, key) => (
                      <tr key={`record-${key}`}>
                        <td>
                          <Button square transparent Component={Link} to={`/records/${record.id}`}>
                            <Icon>edit</Icon>
                          </Button>
                        </td>
                        <td>{record.customer.companyName}</td>
                        <td>{record.type}</td>
                        <td>{record.numberOfTons}</td>
                        <td>{record.axles}</td>
                        <td>{record.type2}</td>
                        <td>{record.cabin}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>)}
              </ContainerVertical>
              <ContainerVertical className="container__records">
                <h2>Won</h2>
                {getFilteredRecords(RecordState.Won).length == 0 ? <span>Er zijn geen gewonnen dossiers</span> :(<Table striped bordered size="sm">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Company</th>
                      <th>Type</th>
                      <th>Tonnage</th>
                      <th>Assen</th>
                      <th>Type2</th>
                      <th>Cabine</th>
                    </tr>
                  </thead>
                  <tbody>
                    {getFilteredRecords(RecordState.Won).map((record, key) => (
                      <tr key={`record-${key}`}>
                        <td>
                          <Button square transparent Component={Link} to={`/records/${record.id}`}>
                            <Icon>edit</Icon>
                          </Button>
                        </td>
                        <td>{record.customer.companyName}</td>
                        <td>{record.type}</td>
                        <td>{record.numberOfTons}</td>
                        <td>{record.axles}</td>
                        <td>{record.type2}</td>
                        <td>{record.cabin}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>)}
              </ContainerVertical>
              <ContainerVertical className="container__records">
                <h2>Lost</h2>
                {getFilteredRecords(RecordState.Lost).length == 0 ? <span>Er zijn geen verloren dossiers</span> :(<Table striped bordered size="sm">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Company</th>
                      <th>Type</th>
                      <th>Tonnage</th>
                      <th>Assen</th>
                      <th>Type2</th>
                      <th>Cabine</th>
                    </tr>
                  </thead>
                  <tbody>
                    {getFilteredRecords(RecordState.Lost).map((record, key) => (
                      <tr key={`record-${key}`}>
                        <td>
                          <Button square transparent Component={Link} to={`/records/${record.id}`}>
                            <Icon>edit</Icon>
                          </Button>
                        </td>
                        <td>{record.customer.companyName}</td>
                        <td>{record.type}</td>
                        <td>{record.numberOfTons}</td>
                        <td>{record.axles}</td>
                        <td>{record.type2}</td>
                        <td>{record.cabin}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>)}
              </ContainerVertical>
            </ContainerHorizontal>
          )}
        </div>
      </ScrollArea>
      <section className="actions">
        <Button primary Component={Link} to="/records/add">
          Dossier aanmaken
        </Button>
      </section>
    </ContainerVertical>
  );
};

export default List;
