import * as types from '../types/constants';
import * as models from '../types/models';
import { Actions } from '../types/actions';

export const requestRecords = (): Actions => ({
  type: types.REQUEST_RECORDS,
});

export const receiveRecords = (records: models.Record[]): Actions => ({
  type: types.RECEIVE_RECORDS,
  records,
});

export const recordsError = (): Actions => ({
  type: types.RECORDS_ERROR,
});

export const requestRecord = (id?: number): Actions => ({
  type: types.REQUEST_RECORD,
  id,
});

export const receiveRecord = (record: models.Record): Actions => ({
  type: types.RECEIVE_RECORD,
  record,
});

export const recordError = (): Actions => ({
  type: types.RECORD_ERROR,
});

export const submitRecord = (record: models.Record): Actions => ({
  type: types.SUBMIT_RECORD,
  record,
});
